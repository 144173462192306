/* eslint-disable */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MaterialTable from '@material-table/core';
import TextField from '@mui/material/TextField';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import FileOpen from '@mui/icons-material/FileOpen';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';

import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

import { useSelector, useDispatch } from 'react-redux';
import { setEmployees } from '../reducers/employees';

import {
  getRequest, postRequest, putRequest, createPhoto,
  getLangUser, numberToMoney, createPDF,
} from '../Cache/cacheStorage';
import { labelLang } from '../Lang/lang';
function ReportExpense() {
  const currentDateForInputMonth = moment().format('YYYY-MM');
  const [dataTable, setdataTable] = useState([]);
  const [detailExpense, setdetailExpense] = useState({
    createdate: '',
    description: '',
    fulldate: '',
    fullhour: '',
    id: '',
    photo: '',
    pdf: '',
    totalExpense: 0,
    isEdit: false,
    comments: [],
  });
  const [editItem, seteditItem] = useState({
    createdate: '',
    description: '',
    fulldate: '',
    fullhour: '',
    id: '',
    photo: '',
    pdf: '',
    totalExpense: 0,
    isEdit: false,
    comments: [],
  });
  const [openDetail, setopenDetail] = useState(false);
  const [dataSalesMonth, setdataSalesMonth] = useState(0);
  const [dataTotalMonth, setdataTotalMonth] = useState(0);

  const [newComment, setNewComment] = useState({
    emailRegister: '',
    createdate: '',
    comment: '',
  });
  const [validatorComments, setValidatorComments] = useState(true);
  const [modalUpdate, setmodalUpdate] = useState(false);
  const [msmAlert, setmsmAlert] = useState('');
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const employee = useSelector((state) => state.employees.value);

  /* A function that is used to set the rol. */
  const handlerSetEmployees = (infoEmployees) => {
    dispatch(setEmployees(infoEmployees));
  };

  const getEmployees = async () => {
    const requestEmployee = await getRequest('employees', {}, 'employees', false);
    handlerSetEmployees(requestEmployee.data.dataResponse);
  };

  useEffect(() => {
    if (employee.length === 0) {
      getEmployees();
    }
  }, []);

  const seriesMensual = [{
    name: '# Gastos',
    data: [],
  },
  {
    name: 'Promedio de gastos',
    data: [],
  },
  {
    name: 'Total de gastos',
    data: [],
  }];

  const OptMensual = {
    chart: {
      id: 'month-apexchart',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      categories: [],
    },
    yaxis: {
      labels: {
        show: false,
        formatter(val) {
          return numberToMoney(val);
        },
      },
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy',
      },
    },
  };

  const getExpense = async (i, e) => {
    const requestExpense = await getRequest('report-expense', { initialdate: i, enddate: e }, 'report-expense', false);
    if (requestExpense.status === 200) {
      if (requestExpense.data.dataResponse.length !== 0) {
        setdataSalesMonth(requestExpense.data.dataResponse[0].totalExpenses.reduce(
          (result, number) => result + number, 0,
        ));
        setdataTotalMonth(requestExpense.data.dataResponse[0].totalExpense.reduce(
          (result, number) => result + number, 0,
        ));

        OptMensual.xaxis.categories.length = 0;
        OptMensual.xaxis.categories = requestExpense.data.dataResponse[0].categories;
        ApexCharts.exec('month-apexchart', 'updateOptions', OptMensual);

        seriesMensual[0].data = requestExpense.data.dataResponse[0].totalExpenses;
        seriesMensual[1].data = requestExpense.data.dataResponse[0].promedioExpense;
        seriesMensual[2].data = requestExpense.data.dataResponse[0].totalExpense;
        ApexCharts.exec('month-apexchart', 'updateSeries', seriesMensual);
      }
    }
  };

  const getExpenseTable = async (i, e) => {
    const requestExpenseTable = await getRequest('expense', { initialdate: i, enddate: e }, 'expense', false);
    if (requestExpenseTable.status === 200) {
      setdataTable(requestExpenseTable.data.dataResponse);
    }
  };

  const getSalesMonthly = async (eventInit, eventEnd, op) => {
    if (op === 0) {
      getExpense(eventInit, eventEnd);
      getExpenseTable(eventInit, eventEnd);
    }

    if (op === 1) {
      getExpense(moment(eventInit).startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'),
        moment(eventInit).endOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'));
      getExpenseTable(moment(eventInit).startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'),
        moment(eventInit).endOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'));
    }
  };

  useEffect(() => {
    getSalesMonthly(moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'),
      moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'), 0);
  }, []);

  const handlerOpenDetail = (e, id) => {
    const item = dataTable.filter((x) => x.id === id);
    setdetailExpense(item[0]);
    setopenDetail(true);
  };

  const handlerCloseDetail = () => {
    setdetailExpense({
      createdate: '',
      description: '',
      fulldate: '',
      fullhour: '',
      id: '',
      pdf: '',
      photo: '',
      totalExpense: 0,
      isEdit: false,
      comments: [],
    });
    setopenDetail(false);
  };

  const handlerOpenModalUpdate = (e) => {
    const item = dataTable.filter((x) => x.id === e);
    seteditItem(item[0]);
    setmodalUpdate(true);
  };

  const handlerCloseModalUpdate = () => {
    seteditItem({});
    setmodalUpdate(false);
  };

  const handleraddComments = (e) => {
    const item = newComment;
    item.comment = e.target.value;
    setNewComment(item);

    if (newComment.comment !== '' || newComment.emailRegister !== '') {
      setValidatorComments(false);
    }
  };

  const handlerUpdate = async () => {
    const itemComment = newComment;
    const itemUpdate = editItem;

    if (itemComment.comment !== '') {
      itemComment.createdate = moment().format('YYYY-MM-DDTHH:mm:ss.SSS');
      itemUpdate.comments.push(itemComment);
      itemUpdate.isEdit = true;

      const responseUpdate = await putRequest('expense', itemUpdate, 'expense', false);
      if (responseUpdate.status === 200) {
        setmodalUpdate((modal) => !modal);
        setmsmAlert('El comentario se ha añadido al Gasto.');
        setalertSuccess(true);
      } else {
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }
    } else {
      setmsmAlert('Error, el campo se encuentra vacio.');
      setalertError(true);
    }
  };

  const changeEmployee = (id) => {
    const nameEmployee = employee.find((x) => x.id === id);
    const commentItem = newComment;
    commentItem.emailRegister = `${nameEmployee.name} ${nameEmployee.lastName}`;

    setNewComment(commentItem);

    if (newComment.comment !== '' || newComment.emailRegister !== '') {
      setValidatorComments(false);
    }
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  const [createExpenseModal, setCreateExpenseModal] = useState(false);
  const [imgCompressed, setimgCompressed] = useState(null);
  const [imgCompressedblob, setimgCompressedblob] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [showPdfPreview, setShowPdfPreview] = useState(false);
  const [newItem, setnewItem] = useState({
    id: '',
    createdate: '',
    totalamount: 0,
    description: '',
    photo: '',
    isEdit: false,
    comments: [],
  });

  const OpenModalCreateExpense = () => {
    setCreateExpenseModal(true);
  };

  const CloseModalCreateExpense = () => {
    setnewItem({
      id: '',
      createdate: '',
      totalamount: 0,
      photo: '',
      isEdit: false,
      comments: [],
    });
    
    setCreateExpenseModal(false);
    setimgCompressed(null);
    setimgCompressedblob(null);
    setPdfFile(null);
    setShowPdfPreview(false);

    setCreateExpenseModal(false);
  };

  const compressImage = (event) => {
    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    imageCompression(imageFile, options)
      .then((compressedFile) => {
        const urlImg = URL.createObjectURL(compressedFile);
        setimgCompressed(urlImg);
        setimgCompressedblob(compressedFile);

        setPdfFile(null);
        setShowPdfPreview(false);

        setTypeFile('image');
      })
      .catch((err) => {
        console.log(err);
      });

  };

  const handlerCreateTotalAmount = (e) => {
    const value = parseInt(e.target.value, 10);
    setnewItem((prevItem) => ({ ...prevItem, totalamount: value }));
  };

  const handlerCreateDescription = (e) => {
    const descrip = e.target.value;
    setnewItem((prevItem) => ({ ...prevItem, description: descrip }));
  };

  const [typeFile, setTypeFile] = useState('');

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
  
    setPdfFile(selectedFile);
    setShowPdfPreview(true);

    setimgCompressed(null);
    setimgCompressedblob(null);
    setTypeFile('pdf');
  };

  const handlerCreate = async () => {
    let validatorFile = "";
    
    if (typeFile === 'image') {
      validatorFile = 'image';
    } else {  
      validatorFile = 'pdf';
    }

    const responseFileExpense = (validatorFile === 'image') ?
      await createPhoto('upload-image-expense', imgCompressedblob)
      : await createPDF('upload-pdf-expense', pdfFile);
  
    if (responseFileExpense.status === 200) {
      const dataCreate = newItem;
      if (typeFile === 'image') {
        dataCreate.photo = responseFileExpense.data.imageUrl;
      } else {
        dataCreate.pdf = responseFileExpense.data.imageUrl;
      }
      dataCreate.createdate = moment().format('YYYY-MM-DDTHH:mm:ss.SSS');
      dataCreate.id = uuidv4();
      setnewItem(dataCreate);

      const responsePost = await postRequest('expense', dataCreate, 'expense', false);
      if (responsePost.status === 200) {
        setnewItem({
          id: '',
          createdate: '',
          totalamount: 0,
          photo: '',
          isEdit: false,
          comments: [],
        });
        setimgCompressed('');
        getSalesMonthly(moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'),
          moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss.SSS'), 0);
        setmsmAlert('El Gasto se ha creado exitosamente.');
        setalertSuccess(true);
      } else {
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }
    }

    CloseModalCreateExpense();
  };

  const commentView = (data) => {
    const rowComment = [
      {
        id: 1,
        name: 'Quien Registra',
        value: data.emailRegister,
      },
      {
        id: 2,
        name: 'Fecha de Registro',
        value: moment(data.createdate).format('DD/MM/YYYY hh:mm:ss A'),
      },
      {
        id: 3,
        name: 'Comentario',
        value: data.comment,
      },
    ];

    return <div className="row py-2 mb-2" style={{ border: '1px Solid #e0e0e0', borderRadius: '0.25rem' }}>
      {rowComment.map((item) => (
        <div className="col-12" key={item.id}>
          <span>
            {item.title}:&nbsp;
            <span className="text-muted">
            {item.value}
            </span>
          </span>
        </div>
      ))}
    </div>;
   };

  return (
    <div className="h-100 px-3 bg-white btn-rounded inside-scroll-container">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-2">
          <h3>
            Reporte de gastos
            <IconButton
              className="float-end"
              color="primary"
              aria-label="settings"
              component="span"
              onClick={() => OpenModalCreateExpense()}
            >
              <AddIcon />
            </IconButton>
          </h3>
        </div>
        <div className="col-12 mb-2">
          <TextField
                variant="standard"
            id="date-month"
            type="month"
            defaultValue={currentDateForInputMonth}
            onChange={(e) => getSalesMonthly(e.target.value, 0, 1)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-2">
          <ReactApexChart
            options={OptMensual}
            series={seriesMensual}
            type="area"
            height={250}
          />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-2">
          <div className="card card-body border-0 shadow-sm">
            <div className="row text-center">
              <h4 className="text-primary">Resumen - Gráfica mensual</h4>
              <div className="col-6 mt-3">
                <span className="h5 text-dark">
                  Gastos registradas
                  <br />
                  <span className="h5 text-muted">
                    {dataSalesMonth}
                  </span>
                </span>
              </div>
              <div className="col-6 mt-3">
                <span className="h5 text-dark">
                  Prom. de gastos
                  <br />
                  <span className="h5 text-muted">
                    {dataTotalMonth !== 0 || dataSalesMonth !== 0 ? (
                      numberToMoney(dataTotalMonth / dataSalesMonth)
                    ) : (
                      numberToMoney(0)
                    )}
                  </span>
                </span>
              </div>
              <div className="col-6 mt-3">
                <span className="h5 text-dark">
                  Total de gastos
                  <br />
                  <span className="h5 text-primary">
                    {
                      numberToMoney(dataTotalMonth)
                    }
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mb-2">
          <MaterialTable
            title="Listado de gastos"
            columns={[
              {
                title: 'Fecha de registro',
                field: 'fulldate',
                width: '25%',
                render: (dataRow) => moment(dataRow.createdate).format('DD/MM/YYYY hh:mm:ss A'),
              },
              {
                title: 'Valor del gasto',
                field: 'totalExpense',
                width: '20%',
                render: (dataRow) => (numberToMoney(dataRow.totalExpense)),
              },
              {
                title: 'Descripción',
                field: 'description',
                width: '50%',
              },
            ]}
            data={dataTable}
            actions={
              [
                {
                  icon: 'list',
                  tooltip: 'Ver detalle',
                  onClick: (event, rowData) => handlerOpenDetail(event, rowData.id),
                },
                {
                  icon: 'edit',
                  tooltip: 'Añadir Comentario',
                  onClick: (event, rowData) => handlerOpenModalUpdate(rowData.id),
                },
              ]
            }
            options={{
              paging: dataTable.length > 100,
              pageSize: 100,
              pageSizeOptions: [100, 150, 200, 300],
              toolbar: false,
              maxColumnSort: 'all_columns',
              rowStyle: (rowData) => {
                if (rowData.isEdit === true) {
                  return { backgroundColor: '#fa343e', color: '#fff' };
                }
              },
            }}
            localization={{
              toolbar: {
                searchPlaceholder: labelLang(getLangUser(), 'table', 'searchPlaceholder'),
                searchTooltip: labelLang(getLangUser(), 'table', 'searchTooltip'),
              },
              header: {
                actions: labelLang(getLangUser(), 'table', 'actions'),
              },
              pagination: {
                labelRowsPerPage: labelLang(getLangUser(), 'table', 'labelRowsPerPage'),
                labelDisplayedRows: labelLang(getLangUser(), 'table', 'labelDisplayedRows'),
                labelRowsSelect: labelLang(getLangUser(), 'table', 'labelRowsSelect'),
                firstAriaLabel: labelLang(getLangUser(), 'table', 'firstAriaLabel'),
                firstTooltip: labelLang(getLangUser(), 'table', 'firstTooltip'),
                previousAriaLabel: labelLang(getLangUser(), 'table', 'previousAriaLabel'),
                previousTooltip: labelLang(getLangUser(), 'table', 'previousTooltip'),
                nextAriaLabel: labelLang(getLangUser(), 'table', 'nextAriaLabel'),
                nextTooltip: labelLang(getLangUser(), 'table', 'nextTooltip'),
                lastAriaLabel: labelLang(getLangUser(), 'table', 'lastAriaLabel'),
                lastTooltip: labelLang(getLangUser(), 'table', 'lastTooltip'),
              },
            }}
          />
        </div>
      </div>

      <Dialog
        open={createExpenseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { CloseModalCreateExpense(); } }}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>
          Registrar gasto
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-6">
              {imgCompressed && (
                <img src={imgCompressed} className="mx-auto shadow img-expense" alt="Registro de gastos" style={{ maxWidth: '600px', borderRadius: '.25rem' }} />
              )}

              {showPdfPreview && <Document file={pdfFile}><Page pageNumber={1} /></Document>}
            </div>

            <div className="col-6 mb-2">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                  <TextField
                    variant="standard"
                    id="name"
                    label="Total Valor *"
                    className="w-100"
                    InputProps={{ inputProps: { min: 0 } }}
                    type="number"
                    onChange={(e) => handlerCreateTotalAmount(e)}
                  />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                  <TextField
                    variant="standard"
                    id="name"
                    label="Descripción del Gastos *"
                    className="w-100"
                    multiline
                    minRows={2}
                    onChange={(e) => handlerCreateDescription(e)}
                  />
                </div>
              </div>
              <div className="col-12 mb-2 d-flex justify-content-center">
                <div className="m-auto text-center">
                  <label htmlFor="icon-button-file-create">
                    <IconButton color="primary" aria-label="upload picture" className="mt-2" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                  <input id="icon-button-file-create" accept="image/*" className="d-none" type="file" onChange={(e) => compressImage(e)} />
                </div>

                <div className="m-auto text-center">
                  <label htmlFor="icon-button-pdf-create">
                    <IconButton color="primary" aria-label="upload picture" className="mt-2" component="span">
                      <FileOpen />
                    </IconButton>
                  </label>
                  <input id="icon-button-pdf-create" className="d-none" type="file" accept="application/pdf" onChange={handleFileChange} />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row mt-3 mb-2">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <button
                    className="btn btn-danger w-100"
                    type="button"
                    onClick={() => CloseModalCreateExpense()}
                  >
                    Cancelar
                  </button>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <button
                    className="btn btn-primary w-100"
                    type="button"
                    onClick={() => handlerCreate()}
                    disabled={
                      !(newItem.totalamount !== 0 && newItem.totalamount !== '' &&
                        newItem.description !== undefined && newItem.description !== '' &&
                        (imgCompressed !== null || pdfFile !== null))
                    }
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDetail}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseDetail(); } }}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="pb-0">
          Detalle de Gasto
          <button className="btn float-end p-0 text-muted" type="button" onClick={handlerCloseDetail}>
            <CloseIcon />
          </button>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-6 mb-3 text-center">
              <div>
                {detailExpense.photo && (
                  <img src={detailExpense.photo} className="img-expense" alt="detalle de gastos - tf payments" />
                )}
                {detailExpense.pdf && <Document file={detailExpense.pdf}><Page pageNumber={1} /></Document>}
              </div>
            </div>
            <div className="col-6 mb-3">
              <div className="row">
                <div className="col-12 mb-2">
                  <span className="h5">
                    Detalle
                  </span>
                </div>
                <div className="col-6 mb-2">
                  <TextField
                    variant="standard"
                    label="F. de creación"
                    id="date-create"
                    className="w-100"
                    type="text"
                    disabled
                    defaultValue={moment(detailExpense.createdate).format('DD/MM/YYYY')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-6 mb-2">
                  <TextField
                    variant="standard"
                    label="H. de creación"
                    id="hour-create"
                    className="w-100"
                    type="text"
                    disabled
                    defaultValue={moment(detailExpense.createdate).format('hh:mm:ss A')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-12 mb-2">
                  <TextField
                    variant="standard"
                    label="Descripción"
                    id="description"
                    className="w-100"
                    type="text"
                    disabled
                    defaultValue={detailExpense.description}
                    multiline
                    minRows={4}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-12 mb-2">
                  <TextField
                    variant="standard"
                    label="Total de Gasto"
                    id="total-expense"
                    className="w-100"
                    type="text"
                    disabled
                    defaultValue={numberToMoney(detailExpense.totalExpense)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-12 mb-2">
                  <span className="h5">
                    Comentarios
                  </span>
                </div>
                <div className="col-12 mb-2">
                  {detailExpense.isEdit === true && (
                    detailExpense.comments.map((item, i) => (
                      <div className="w-100" key={item.createdate}>
                        <a
                          className="text-decoration-none"
                          data-bs-toggle="collapse"
                          href={`#commentNumber${i}`}
                          role="button"
                          aria-expanded="false"
                          aria-controls={`commentNumber${i}`}
                        >
                          {moment(item.createdate).format('DD/MM/YYYY hh:mm:ss A')}
                          <ArrowDropDownIcon />
                        </a>
                        <div
                          className="collapse mt-1 px-2"
                          id={`commentNumber${i}`}
                        >
                          <>
                            {commentView(item)}
                          </>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalUpdate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalUpdate(); } }}
      >
        <DialogTitle>
          Añadir comentario
        </DialogTitle>
        <DialogContent>
          <div className="row pb-2">
            <div className="col-12 mb-2">
              <TextField
                variant="standard"
                label="Comentarios *"
                id="reason-edit-transaction"
                type="text"
                className="w-100"
                multiline
                minRows={2}
                onChange={(e) => handleraddComments(e)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-12 mb-2">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="label-select-employee">Empleado quien registra</InputLabel>
                <Select
                  labelId="select-update-category"
                  id="select-update-category"
                  defaultValue=""
                  onChange={(e) => changeEmployee(e.target.value)}
                >
                  {
                    employee.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {`${item.name} ${item.lastName}`}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            <div className="col-6">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalUpdate}>
                Cancelar
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={handlerUpdate}
                disabled={validatorComments}
              >
                Guardar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ReportExpense;
