import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddAlarmIcon from '@mui/icons-material///AddAlarm';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { useSelector, useDispatch } from 'react-redux';
import { setEmployees } from '../reducers/employees';

import {
  getRequest, postRequest, putRequest,
} from '../Cache/cacheStorage';

function ClockIn() {
  const [employeeRegistre, setEmployeeRegistre] = useState({
    id: '',
    createdate: '',
    employees: [],
  });

  const [itemEmployeeRegistre, setitemEmployeeRegistre] = useState({
    id: '0',
    name: '',
    createdate: '',
    clockIn: '',
    clockOut: '',
    comments: '',
  });

  const [currentTime, setcurrentTime] = useState(new Date());
  const [msmAlert, setmsmAlert] = useState('');
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);
  const [filterEmployees, setfilterEmployees] = useState([]);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const employee = useSelector((state) => state.employees.value);

  const handlerSetEmployees = (infoEmployees) => {
    dispatch(setEmployees(infoEmployees));
  };

  const getEmployees = async () => {
    const requestEmployee = await getRequest('employees', {}, 'employees', false);
    handlerSetEmployees(requestEmployee.data.dataResponse);
  };

  useEffect(() => {
    if (employee.length === 0) {
      getEmployees();
    }
  }, []);

  const handlerSetEmployee = (e) => {
    if (e !== '0') {
      const itemEmployee = employee.find((x) => x.id === e);

      const employeeRegister = itemEmployeeRegistre;
      employeeRegister.id = itemEmployee.id;
      employeeRegister.name = `${itemEmployee.name} ${itemEmployee.lastName}`;
      employeeRegister.createdate = moment().format('YYYY-MM-DDTHH:mm:ss.SSS');
      employeeRegister.clockIn = moment().format('hh:mm:ss A');
    } else {
      setitemEmployeeRegistre({
        id: '0',
        name: '',
        createdate: '',
        clockIn: '',
        clockOut: '',
        comments: '',
      });
    }
  };

  const handlerinsertClockin = async () => {
    const newItem = employeeRegistre;
    newItem.id = uuidv4();
    newItem.createdate = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
    newItem.employees = [];

    const requestClockIn = await postRequest('clock-in', newItem, 'clock-in', false);
    if (requestClockIn.status === 200) {
      setEmployeeRegistre(newItem);
      setmsmAlert('El registro de asistencia se ha creado exitosamente.');
      setalertSuccess(true);
    }

    if (requestClockIn.status !== 200) {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };

  const handlerUpdateRegister = async (emp) => {
    const responseUpdate = await putRequest('clock-in', emp, 'clock-in', false);
    if (responseUpdate.status === 200) {
      setitemEmployeeRegistre({
        id: '0',
        name: '',
        createdate: '',
        clockIn: '',
        clockOut: '',
        comments: '',
      });

      setmsmAlert('Registro exitoso.');
      setalertSuccess(true);
    } else {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };

  const handlerAddEmployee = () => {
    const itemAddRegister = itemEmployeeRegistre;
    employeeRegistre.employees.push(itemAddRegister);

    const filteredItems = filterEmployees;
    filteredItems.push(itemAddRegister.id);
    setfilterEmployees(filteredItems);

    setitemEmployeeRegistre({
      id: '0',
      name: '',
      createdate: '',
      clockIn: '',
      clockOut: '',
      comments: '',
    });

    handlerUpdateRegister(employeeRegistre);
  };

  const handlerAddClockOut = (e) => {
    if (e !== '0') {
      const itemEmployee = employeeRegistre.employees.find((x) => x.id === e);
      itemEmployee.clockOut = currentTime.toLocaleString('en-US', {
        hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true,
      });

      handlerUpdateRegister(employeeRegistre);
    } else {
      setitemEmployeeRegistre({
        id: '0',
        name: '',
        createdate: '',
        clockIn: '',
        clockOut: '',
        comments: '',
      });
    }
  };

  const handlerClockinToday = async () => {
    const dateObject = {
      initialdate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
      enddate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS'),
    };

    const requestClockInToday = await postRequest('clock-in-today', dateObject, 'clock-in-today', false);
    if (requestClockInToday.status === 200) {
      if (requestClockInToday.data.dataResponse.length !== 0) {
        const item = requestClockInToday.data.dataResponse[0];

        const arrayId = [];
        item.employees.forEach((i) => arrayId.push(i.id));
        setfilterEmployees(arrayId);

        const newEmployeeRegistre = {
          id: item.id,
          createdate: item.createdate,
          employees: item.employees,
        };

        setEmployeeRegistre(prevEmployeeRegistre => ({...prevEmployeeRegistre, ...newEmployeeRegistre}));
      }
    }
  };

  useEffect(() => {
    handlerClockinToday();
  }, []);

  const updateCurrentTime = () => {
    setcurrentTime(new Date());
  };

  useEffect(() => {
    const timerId = setInterval(updateCurrentTime, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  const listEmployee = (employee) => {
    const { id, name, clockIn, clockOut } = employee;

    return <div key={id} className="col-12 border-bottom">
      <div className="row my-2">
        <div className="col-3">
          <span className="align-middle">
            {name}
          </span>
        </div>
        <div className="col-3">
          <span className="align-middle">
            {clockIn}
          </span>
        </div>
        <div className="col-3">
          <span className="align-middle">
            {clockOut}
          </span>
        </div>
        <div className="col-3">
          {!clockOut && (
            <button
              type="button"
              className="btn-rounded btn btn-primary w-100"
              value={id}
              onClick={(e) => handlerAddClockOut(e.target.value)}
            >
              Agregar hora de salida
            </button>
          )}
        </div>
      </div>
    </div>;
  };

  return (
    <div className="w-100 mt-4 p-3 btn-rounded main-container">
      <div className="h-100 bg-white btn-rounded detail-content">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-3">
            <h3 className="text-dark ps-3">
              Lista de asistencia
            </h3>
          </div>
        </div>

        <div className="h-25 d-flex justify-content-center">
          <div className="align-self-center pe-2 w-50 text-center">
            <span className="bg-secondary px-4 py-2 rounded-2" style={{ fontFamily: 'monospace', fontSize: '50px', boxShadow: 'rgb(0 0 0) 0px 1px 10px 5px inset' }}>
              {currentTime.toLocaleString('en-US', {
                hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true,
              })}
            </span>
          </div>
          <div className="align-self-center ps-2 w-50">
            {employeeRegistre.id !== '' ? (
              <div className="w-100 h-100 text-center">
                <FormControl variant="standard" className="w-50">
                  <InputLabel id="label-select-employee">Empleado</InputLabel>
                  <Select
                    labelId="select-update-category"
                    id="select-update-category"
                    defaultValue={itemEmployeeRegistre.id}
                    onChange={(e) => handlerSetEmployee(e.target.value)}
                  >
                    <MenuItem value="0">Seleccionar</MenuItem>
                    {
                      employee.filter((x) => !filterEmployees.includes(x.id)).map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.id}
                        >
                          {item.name}
                          &nbsp;
                          {item.lastName}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                <br />
                <button
                  type="button"
                  className="btn btn-primary w-50 mt-3 btn-rounded"
                  onClick={handlerAddEmployee}
                  disabled={itemEmployeeRegistre.id === '0'}
                >
                  Agregar Asistencia
                </button>
              </div>
            ) : (
              <div className="w-100 h-100 text-center">
                <button
                  type="button"
                  className="btn border-none shadow-sm p-3"
                  onClick={handlerinsertClockin}
                >
                  <AddAlarmIcon className="text-primary" fontSize="large" />
                  <span className="h5 d-block">
                    Agregar registro de asistencia de empleados
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="container mb-5">
          <div className="card card-body shadow-sm border-0">
            <span className="h5 mt-2">
              Lista
              <span className="h5 float-end">
                {moment().format('DD/MM/YYYY')}
              </span>
            </span>
            <div className="row m-2">
              <div className="col-12">
                <div className="row">
                  <div className="col-3">
                    <span className="small">
                      Empleado
                    </span>
                  </div>
                  <div className="col-3">
                    <span className="small">
                      Hora de Ingreso
                    </span>
                  </div>
                  <div className="col-3">
                    <span className="small">
                      Hora de Salida
                    </span>
                  </div>
                  <div className="col-3">
                    <span className="small">
                      &nbsp;
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-bottom border-dark" />
            <div className="row m-2">
              {employeeRegistre.employees.map((item) => (
                listEmployee(item)
              ))}
            </div>
          </div>
        </div>

      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ClockIn;
