/* eslint-disable */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import MaterialTable from '@material-table/core';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Skeleton from '@mui/material/Skeleton';

import Loading from '../Loading/Loading';

import { useSelector, useDispatch } from 'react-redux';
import { setEmployees } from '../reducers/employees';
import { setProducts } from '../reducers/products';
import { setCategory } from '../reducers/category';
import {
  setStock, insertProductStock, updateAmountProduct, resetStock,
  deleteProductStock, addFrozenAmountToStock, addFrozenAmounts
} from '../reducers/stock';

import {
  getRequest, postRequest, putRequest, deleteRequest, 
  getSubMenu, createPhoto, getLangUser, numberToMoney,
} from '../Cache/cacheStorage';
import { labelLang } from '../Lang/lang';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Stock() {
  const submenu = getSubMenu('settings', 'stock');

  const apiUrl = 'stock';
  const storedData = 'stock';
  const [selectCategory, setSelectCategory] = useState('');
  const [productsFilter, setProductsFilter] = useState([]);

  const [modalItem, setModalItem] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(false);
  const [item, setItem] = useState({
    id: '',
    name: '',
    category: '',
    categoryName: '',
    price: 0,
    amountStock: 0,
    amountSell: 0,
    amountRemaining: 0,
    photo: '',
    active: true,
    amountFrozenProduct: 0,
    isFrozen: false,
  });

  const [imgCompressedblob, setImgCompressedblob] = useState();
  const [newImg, setNewImg] = useState(false);

  const [itemUpdate, setItemUpdate] = useState({
    id: '',
    name: '',
    addAmount: 0,
    dateUpdate: '',
    employee: '',
    employeeName: '',
  });

  const [msmAlert, setMsmAlert] = useState('');
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);

  const dispatch = useDispatch();

  // #region Stock
  const stock = useSelector((state) => state.stock.value);

  const handlerAddFrozenAmountToStock = (infoStock) => {
    dispatch(addFrozenAmountToStock(infoStock));
  };

  const handlerAddFrozenAmounts = (infoStock) => {
    dispatch(addFrozenAmounts(infoStock));
  };

  const handlerSetStock = (infoStock) => {
    dispatch(setStock(infoStock));
  };

  const handlerInsertStock = (infoStock) => {
    dispatch(insertProductStock(infoStock));
  };

  const handlerUpdateAmountProduct = (infoStock) => {
    dispatch(updateAmountProduct(infoStock));
  };

  const habdlerDeleteProductStock = (infoStock) => {
    dispatch(deleteProductStock(infoStock));
  }

  const handlerResetStock = () => {
    dispatch(resetStock());
  };

  const getStock = async () => {
    const requestStock = await getRequest(apiUrl, {}, storedData, false);
    handlerSetStock(requestStock.data.dataResponse);
  };

  useEffect(() => {
    handlerResetStock();
    getStock();
  }, []);
  // #endregion

  // #region Employees
  const employees = useSelector((state) => state.employees.value);

  const handlerSetEmployees = (infoEmployees) => {
    dispatch(setEmployees(infoEmployees));
  };

  const getEmployees = async () => {
    const requestEmployee = await getRequest('employees', {}, 'employees', false);
    handlerSetEmployees(requestEmployee.data.dataResponse);
  };

  (() => {
    if (employees.length === 0) {
      getEmployees();
    }
  })();
  // #endregion

  // #region PRODUCTS
  const products = useSelector((state) => state.products.value);

  const handlerSetProducts = (infoProducts) => {
    dispatch(setProducts(infoProducts));
  };

  const getProduct = async () => {
    const requestProduct = await getRequest('product', {}, 'product', false);
    handlerSetProducts(requestProduct.data.dataResponse);
  };

  (() => {
    if (products.length === 0) {
      getProduct();
    }
  })();
  // #endregion

  // #region CATEGORY
  const categories = useSelector((state) => state.category.value);

  const handlerSetCategory = (infoCategory) => {
    dispatch(setCategory(infoCategory));
  };

  const getCategory = async () => {
    const requestCategory = await getRequest('category', {}, 'category', false);
    handlerSetCategory(requestCategory.data.dataResponse);
  };

  (() => {
    if (categories.length === 0) {
      getCategory();
    }
  })();
  // #endregion

  const handlerOpenModalItem = (id) => {
    if (id !== '') {
      const obj = stock.filter((x) => x.id === id);
      setItem((prevState) => ({
        ...prevState,
        id: obj[0].id,
        name: obj[0].name,
        category: obj[0].category,
        categoryName: obj[0].categoryName,
        price: obj[0].price,
        amountStock: obj[0].amountStock,
        amountSell: obj[0].amountSell,
        amountRemaining: obj[0].amountRemaining,
        photo: obj[0].photo,
        active: obj[0].active,
        amountFrozenProduct: obj[0].amountFrozenProduct,
        isFrozen: obj[0].isFrozen,
      }));

      setItemUpdate((prevState) => ({ ...prevState, id: obj[0].id }));
      setItemUpdate((prevState) => ({ ...prevState, name: obj[0].name }));

      setModalItemEdit(true);
    } else {
      setModalItem(true);
    }
  };

  const handlerFilterProduct = (e) => {
    setSelectCategory(e.target.value);
    setProductsFilter(products.filter((x) => x.category === e.target.value));

    setItem({
      id: '',
      name: '',
      category: '',
      price: 0,
      amountStock: '0',
      amountSell: 0,
      amountRemaining: 0,
      isEdit: false,
      comments: [],
      photo: '',
      amountFrozenProduct: 0,
      isFrozen: false,
    });
  };

  const handlerCreateProduct = (e) => {
    const obj = products.filter((x) => x.id === e.target.value);
    setItem((prevState) => ({
      ...prevState,
      id: obj[0].id,
      name: obj[0].name,
      category: obj[0].category,
      categoryName: obj[0].categoryName,
      price: obj[0].price,
      photo: obj[0].photo,
      active: true,
    }));
  };

  const handlerCloseEdit = () => {
    setItem({
      id: '',
      name: '',
      category: '',
      price: 0,
      amountStock: 0,
      amountSell: 0,
      amountRemaining: 0,
      isEdit: false,
      comments: [],
      photo: '',
      amountFrozenProduct: 0,
      isFrozen: false,
    });

    setItemUpdate((prevState) => ({ ...prevState, id: '' }));
    setItemUpdate((prevState) => ({ ...prevState, name: '' }));
    setItemUpdate((prevState) => ({ ...prevState, addAmount: 0 }));
    setItemUpdate((prevState) => ({ ...prevState, dateUpdate: '' }));
    setItemUpdate((prevState) => ({ ...prevState, employee: '' }));
    setItemUpdate((prevState) => ({ ...prevState, employeeName: '' }));
    setItemUpdate((prevState) => ({ ...prevState, amountFrozenProduct: 0 }));
    setItemUpdate((prevState) => ({ ...prevState, isFrozen: false }));

    setProductsFilter([]);
    setModalItemEdit(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'amountStock') {
      setItem((prevState) => ({ ...prevState, [name]: value, amountRemaining: (parseInt(value, 10) - parseInt(item.amountSell, 10)) }));
    }

    if (name === 'amountFrozenProduct') {
      setItem((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const [amountToAddFrozen, setAmountToAddFrozen] = useState(0);

  const handleAddMoreFrozenAmount  = (e) => {
    const { value } = e.target;
    setAmountToAddFrozen(value);
  };

  const [amountTransfer, setAmountTransfer] = useState(0);

  const handleChangeTranferToStock = (e) => {
    const { value } = e.target;
    setAmountTransfer(value);
  };

  const handlerAmountUpdate = (e) => {
    if (e.target.value === '') {
      setItemUpdate((prevState) => ({ ...prevState, addAmount: 0 }));
    } else {
      setItemUpdate((prevState) => ({ ...prevState, addAmount: parseInt(e.target.value, 10) }));
    }
  };

  const handlerSetEmployeeUpdate = (e) => {
    const employeeItem = employees.filter((x) => x.id === e.target.value);

    setItemUpdate((prevState) => ({ ...prevState, employee: employeeItem[0].id }));
    setItemUpdate((prevState) => ({ ...prevState, employeeName: `${employeeItem[0].name} ${employeeItem[0].lastName}` }));
    setItemUpdate((prevState) => ({ ...prevState, dateUpdate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS') }));
  };

  const handleUpdate = async () => {
    const updateData = await putRequest(apiUrl, itemUpdate, storedData, false);

    if (updateData.status === 200) {
      handlerUpdateAmountProduct(itemUpdate);
      handlerCloseEdit();
      setMsmAlert('El Inventario se ha actualizado exitosamente.');
      setAlertSuccess(true);
    } else {
      setMsmAlert('Error en el registro, por favor intente de nuevo.');
      setAlertError(true);
    }
  };

  const addNewProduct = async () => {
    const responseUpdate = await postRequest('stock', item, 'stock', false);
    if (responseUpdate.status === 200) {
      handlerInsertStock(item);
      setMsmAlert('Registro exitoso.');
      setAlertSuccess(true);
      setItem({
        id: '', name: '', category: '', categoryName: '', price: 0, amountStock: 0, amountSell: 0, amountRemaining: 0, photo: '', active: true, amountFrozenProduct: 0, isFrozen: false,
      });
      setProductsFilter([]);
      setSelectCategory('');
      setModalItem(false);
      setImgCompressedblob(null);
      setNewImg(false);
    } else {
      setMsmAlert('Error en el registro, por favor intente de nuevo.');
      setAlertError(true);
    }
  };

  const handlerAdd = async () => {
    if (newImg === true) {
      const responsePhoto = await createPhoto('upload-image', imgCompressedblob);
      if (responsePhoto.status === 200) {
        const itemData = item;
        itemData.photo = responsePhoto.data.imageUrl;
        setItem(itemData);
      }
    }

    if (item.isFrozen) {
      setItem(prevItem => ({
        ...prevItem,
        amountStock: 0,
        amountRemaining: 0,
      }));
    } else {
      setItem(prevItem => ({
        ...prevItem,
        amountFrozenProduct: 0
      }));
    }

    addNewProduct();
  };

  const resetForm = () => {
    setItem({
      id: '',
      name: '',
      category: '',
      categoryName: '',
      price: 0,
      amountStock: 0,
      amountSell: 0,
      amountRemaining: 0,
      photo: '',
      active: true
    });
    setImgCompressedblob(null);
    setNewImg(false);
  };
  
  const resetFilters = () => {
    setProductsFilter([]);
  };
  
  const handlerClose = () => {
    resetForm();
    resetFilters();
    setModalItem(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertError(false);
  };

  const [modalDelete, setModalDelete] = useState(false);
  const [idDelete, setIdDelete] = useState('');

  const handlerDeleteItem = (id) => {
    setIdDelete(id);
    setModalDelete(true);
  };

  const handlerCloseDeleteItem = () => {
    setIdDelete('');
    setModalDelete(false);
  };

  const handlerDelete = async () => {
    const responseUpdate = await deleteRequest(apiUrl, { id: idDelete }, storedData, false);
    if (responseUpdate.status === 200) {
      habdlerDeleteProductStock(idDelete);
      setMsmAlert('El Producto se ha eliminado exitosamente del inventario.');
      setAlertSuccess(true);
      handlerCloseDeleteItem();
    } else {
      setMsmAlert('Error en el registro, por favor intente de nuevo.');
      setAlertError(true);
    }
  };

  const handlerCheckFrozenProduct = () => {
    setItem((prevState) => ({
      ...prevState,
      isFrozen: !prevState.isFrozen,
    }));
  };

  const [transferAmountModal, setTransferAmountModal] = useState(false);

  const handlerCloseModalTranferFrizerToAmountRemaining = () => {
    setTransferAmountModal(false);
    setItem((prevState) => ({
      ...prevState,
      id: '', name: '', category: '', categoryName: '', price: 0, amountStock: 0, amountSell: 0, amountRemaining: 0, photo: '', active: true, amountFrozenProduct: 0, isFrozen: false,
    }));
    setItemUpdate({
      id: '',
      name: '',
      addAmount: 0,
      dateUpdate: '',
      employee: '',
      employeeName: '',
    });
    setAmountTransfer(0);
  };

  const handlerOpenModalTranferFrizerToAmountRemaining = (id) => {
    const obj = stock.filter((x) => x.id === id);
    setItem((prevState) => ({
      ...prevState,
      id: obj[0].id,
      name: obj[0].name,
      category: obj[0].category,
      categoryName: obj[0].categoryName,
      price: obj[0].price,
      amountStock: obj[0].amountStock,
      amountSell: obj[0].amountSell,
      amountRemaining: obj[0].amountRemaining,
      photo: obj[0].photo,
      active: obj[0].active,
      amountFrozenProduct: obj[0].amountFrozenProduct,
      isFrozen: obj[0].isFrozen,
    }));

    setAmountTransfer(0);
    setTransferAmountModal(true);
  };

  const handlerFrozenUpdate = async () => {
    const dataUpdate = {
      id: item.id,
      name: item.name,
      amountFrozenProduct: parseInt(parseInt(item.amountFrozenProduct, 10) + parseInt(amountToAddFrozen, 10)),
    };

    const updateData = await putRequest(`frozen-${apiUrl}`, dataUpdate, storedData, false);

    if (updateData.status === 200) {
      handlerAddFrozenAmounts(dataUpdate);
      handlerCloseModalAddToFrozenStock();
      setAmountToAddFrozen(0);
      setMsmAlert('El Inventario se ha actualizado exitosamente.');
      setAlertSuccess(true);
    } else {
      setMsmAlert('Error en el registro, por favor intente de nuevo.');
      setAlertError(true);
    }
  };

  const [addToFrozenStockModal, setAddToFrozenStockModal] = useState(false);

  const handlerOpenModalAddToFrozenStock = (id) => {
    const obj = stock.filter((x) => x.id === id);
    setItem((prevState) => ({
      ...prevState,
      id: obj[0].id,
      name: obj[0].name,
      category: obj[0].category,
      categoryName: obj[0].categoryName,
      price: obj[0].price,
      amountStock: obj[0].amountStock,
      amountSell: obj[0].amountSell,
      amountRemaining: obj[0].amountRemaining,
      photo: obj[0].photo,
      active: obj[0].active,
      amountFrozenProduct: obj[0].amountFrozenProduct,
      isFrozen: obj[0].isFrozen,
    }));

    setAddToFrozenStockModal(true);
  };

  const handlerCloseModalAddToFrozenStock = () => {
    setItem((prevState) => ({
      ...prevState,
      id: '', name: '', category: '', categoryName: '', price: 0, amountStock: 0, amountSell: 0, amountRemaining: 0, photo: '', active: true, amountFrozenProduct: 0, isFrozen: false,
    }));

    setAmountToAddFrozen(0);
    setAddToFrozenStockModal(false);
  };

  const handlerUpdatetranferFrozenAmount = async () => {
    const dataUpdate = {
      id: item.id,
      name: item.name,
      amountFrozenProduct: parseInt(item.amountFrozenProduct - parseInt(amountTransfer, 10)),
      amountRemaining: parseInt(item.amountRemaining + parseInt(amountTransfer, 10)),
      amountStock: parseInt(item.amountStock + parseInt(amountTransfer, 10))
    };

    const updateData = await putRequest(`frozen-to-${apiUrl}`, dataUpdate, storedData, false);
    if (updateData.status === 200) {
      handlerAddFrozenAmountToStock(dataUpdate);
      handlerCloseModalTranferFrizerToAmountRemaining();
      setMsmAlert('El Inventario se ha actualizado exitosamente.');
      setAlertSuccess(true);
    } else {
      setMsmAlert('Error en el registro, por favor intente de nuevo.');
      setAlertError(true);
    }
  }

  return (
    <div className="h-100 px-3 bg-white btn-rounded inside-scroll-container">
      {stock.length !== 0 ? (
        <MaterialTable
          title={<h3>Inventario</h3>}
          columns={[
            {
              title: 'Nombre',
              field: 'name',
            },
            {
              title: 'Prod. congelado',
              field: 'isFrozen',
              render: (dataRow) => (dataRow.isFrozen ? 'Si' : 'No'),
            },
            {
              title: 'Categoría',
              field: 'categoryName',
            },
            {
              title: 'Cant. restante en vitrina',
              field: 'amountRemaining',
            },
            {
              title: 'Precio',
              field: 'price',
              render: (dataRow) => (numberToMoney(dataRow.price)),
            },
          ]}
          data={stock}
          actions={
            [
              (rowdata) => ({
                icon: 'edit',
                tooltip: 'Agregar al Stock',
                hidden: rowdata.isFrozen,
                disabled: rowdata.isFrozen === true,
                onClick: (event, rowData) => {
                  if (rowdata.isFrozen === false) {
                    handlerOpenModalItem(rowData.id)
                  }
                },
              }),

              (rowdata) => ({
                icon: 'edit',
                tooltip: 'Agregar al congelador',
                hidden: !rowdata.isFrozen,
                disabled: rowdata.isFrozen === false,
                onClick: (event, rowData) => {
                  if (rowdata.isFrozen === true) {
                    handlerOpenModalAddToFrozenStock(rowData.id)
                  }
                },
              }),

              (rowdata) => ({
                icon: 'compare_arrows',
                tooltip: rowdata.amountFrozenProduct === 0 ? 'Primero agrega cantidades al congelador' : 'Mover del congelador a vitrina',
                hidden: !rowdata.isFrozen,
                disabled: rowdata.amountFrozenProduct === 0,
                onClick: (event, rowData) => {
                  if (rowdata.isFrozen === true) {
                    handlerOpenModalTranferFrizerToAmountRemaining(rowData.id)
                  }
                },
              }),

              {
                icon: 'add',
                tooltip: 'Crear',
                isFreeAction: true,
                onClick: () => handlerOpenModalItem(''),
                disabled: submenu.create !== true,
              },
              {
                icon: 'delete',
                tooltip: 'Eliminar',
                onClick: (event, rowData) => handlerDeleteItem(rowData.id),
                disabled: submenu.delete !== true,
                hidden: false,
              },
            ]
          }
          options={{
            maxColumnSort: 'all_columns',
            paging: stock.length > 100,
            pageSize: 100,
            pageSizeOptions: [100, 150, 200, 300],
          }}
          localization={{
            toolbar: {
              searchPlaceholder: labelLang(getLangUser(), 'table', 'searchPlaceholder'),
              searchTooltip: labelLang(getLangUser(), 'table', 'searchTooltip'),
            },
            header: {
              actions: labelLang(getLangUser(), 'table', 'actions'),
            },
            pagination: {
              labelRowsPerPage: labelLang(getLangUser(), 'table', 'labelRowsPerPage'),
              labelDisplayedRows: labelLang(getLangUser(), 'table', 'labelDisplayedRows'),
              labelRowsSelect: labelLang(getLangUser(), 'table', 'labelRowsSelect'),
              firstAriaLabel: labelLang(getLangUser(), 'table', 'firstAriaLabel'),
              firstTooltip: labelLang(getLangUser(), 'table', 'firstTooltip'),
              previousAriaLabel: labelLang(getLangUser(), 'table', 'previousAriaLabel'),
              previousTooltip: labelLang(getLangUser(), 'table', 'previousTooltip'),
              nextAriaLabel: labelLang(getLangUser(), 'table', 'nextAriaLabel'),
              nextTooltip: labelLang(getLangUser(), 'table', 'nextTooltip'),
              lastAriaLabel: labelLang(getLangUser(), 'table', 'lastAriaLabel'),
              lastTooltip: labelLang(getLangUser(), 'table', 'lastTooltip'),
            },
          }}
        />
      ) : (
        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
          <h3 className="mb-4">Inventario</h3>
          <Loading />
        </div>
      )}

      <Dialog
        open={transferAmountModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalTranferFrizerToAmountRemaining(); } }}
      >
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-2">
              <h4>
                Mover del congelador a stock
              </h4>
            </div>
            <div className="col-12 mb-2">
              <div className="col-12 mb-1">
                <TextField
                  variant="standard"
                  error={parseInt(item.amountFrozenProduct, 10) === 0 || item.amountFrozenProduct === '' || Number.isNaN(item.amountFrozenProduct)}
                  label="Cantidad en el congelador *"
                  className="w-100"
                  name="amountStock"
                  value={item.amountFrozenProduct - amountTransfer}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 mb-1">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="amountFrozenProduct">Cant. del congelador a vitrina</InputLabel>
                <Select
                  id="amountFrozenProduct"
                  labelId="amountFrozenProduct"
                  name="amountFrozenProduct"
                  value={amountTransfer}
                  onChange={handleChangeTranferToStock}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="0">
                    <em>Seleccionar</em>
                  </MenuItem>
                  {Array.from({ length: item.amountFrozenProduct }, (_, index) => index + 1).map((cant) => (
                    <MenuItem key={cant} value={cant}>{cant}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 mb-3 text-center">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="label-select-employee">Empleado quien registra</InputLabel>
                <Select
                  labelId="select-update-category"
                  id="select-update-category"
                  defaultValue=""
                  name="employee"
                  error={itemUpdate.employee === '' || itemUpdate.employeeName === ''}
                  onChange={handlerSetEmployeeUpdate}
                  MenuProps={MenuProps}
                >
                  {
                    employees.map((employeeItem) => (
                      <MenuItem key={employeeItem.id} value={employeeItem.id}>
                        {`${employeeItem.name} ${employeeItem.lastName}`}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            <div className="col-6">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalTranferFrizerToAmountRemaining}>Cancelar</button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={handlerUpdatetranferFrozenAmount}
                disabled={
                  itemUpdate.addAmount !== ''
                  && !Number.isNaN(itemUpdate.addAmount)
                  && parseInt(itemUpdate.addAmount, 10) === 0
                  && parseInt(itemUpdate.addAmount, 10) <= 0
                  && itemUpdate.employee === ''
                  && itemUpdate.employeeName === ''
                }
              >
                Agregar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={addToFrozenStockModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalAddToFrozenStock(); } }}
      >
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-2">
              <h4>
                Agregar cantidades al congelador
              </h4>
            </div>
            <div className="col-12 mb-2">
              <div className="col-12 mb-1">
                <TextField
                  variant="standard"
                  error={parseInt(item.amountFrozenProduct, 10) === 0 || item.amountFrozenProduct === '' || Number.isNaN(item.amountFrozenProduct)}
                  label="Cantidad actual en el congelador *"
                  className="w-100"
                  name="amountStock"
                  value={item.amountFrozenProduct}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 mb-2">
              <div className="col-12 mb-1">
                <TextField
                  variant="standard"
                  error={parseInt(amountToAddFrozen, 10) === 0 || amountToAddFrozen === '' || Number.isNaN(amountToAddFrozen)}
                  label="Cantidad a agregar al congelador *"
                  className="w-100"
                  name="amountFrozenProduct"
                  value={amountToAddFrozen}
                  onChange={handleAddMoreFrozenAmount}
                />
              </div>
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalAddToFrozenStock}>Cancelar</button>
            </div>
            <div className="col-6 mb-2">
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={handlerFrozenUpdate}
                disabled={
                  parseInt(amountToAddFrozen, 10) === 0 || amountToAddFrozen === '' || Number.isNaN(amountToAddFrozen)
                }
              >
                Agregar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalItemEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseEdit(); } }}
      >
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-2">
              <h4>
                Editar
              </h4>
            </div>
            <div className="col-6 mb-2">
              <TextField
                variant="standard"
                label="Nombre"
                className="w-100"
                name="amountStock"
                value={item.name}
                disabled
              />
            </div>
            <div className="col-6 mb-2">
              <TextField
                variant="standard"
                label="Cantidad en vitrina *"
                className="w-100"
                name="amountStock"
                value={item.amountRemaining}
                disabled
              />
            </div>
            <div className="col-12 mb-2">
              <TextField
                variant="standard"
                error={
                  parseInt(itemUpdate.addAmount, 10) === 0
                  && itemUpdate.addAmount === ''
                  && Number.isNaN(itemUpdate.addAmount)
                  && parseInt(itemUpdate.addAmount, 10) < 0
                }
                helperText={
                  itemUpdate.addAmount === '' ? (
                    'Ingrese un valor Valido'
                  )
                  : parseInt(itemUpdate.addAmount, 10) === 0 ? (
                    'El valor no puede ser igual a 0'
                  ) : parseInt(itemUpdate.addAmount, 10) < 0 ? (
                    'El valor no puede ser negativo'
                  ) : ''
                }
                label="Cantidad para agregar *"
                className="w-100"
                name="amountStock"
                defaultValue={itemUpdate.addAmount}
                onChange={handlerAmountUpdate}
              />
            </div>
            <div className="col-12 mb-3 text-center">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="label-select-employee">Empleado quien registra</InputLabel>
                <Select
                  labelId="select-update-category"
                  id="select-update-category"
                  defaultValue=""
                  name="employee"
                  error={itemUpdate.employee === '' || itemUpdate.employeeName === ''}
                  onChange={handlerSetEmployeeUpdate}
                  MenuProps={MenuProps}
                >
                  {
                    employees.map((employeeItem) => (
                      <MenuItem key={employeeItem.id} value={employeeItem.id}>
                        {`${employeeItem.name} ${employeeItem.lastName}`}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseEdit}>Cancelar</button>
            </div>
            <div className="col-6 mb-2">
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={handleUpdate}
                disabled={
                  itemUpdate.addAmount !== ''
                  && !Number.isNaN(itemUpdate.addAmount)
                  && parseInt(itemUpdate.addAmount, 10) === 0
                  && parseInt(itemUpdate.addAmount, 10) <= 0
                  && itemUpdate.employee === ''
                  && itemUpdate.employeeName === ''
                }
              >
                Agregar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalItem}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerClose(); } }}
      >
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-2">
              <h4>
                Agregar a Inventario
              </h4>
            </div>
            <div className="col-6 mb-2">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="label-select-category">Categoría</InputLabel>
                <Select
                  id="select-category"
                  labelId="select-category"
                  name="category"
                  value={selectCategory !== '' ? selectCategory : '0'}
                  onChange={handlerFilterProduct}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="0">
                    <em>Seleccionar</em>
                  </MenuItem>
                  {
                    categories.filter((x) => x.enableStock === true).map((cat) => (
                      <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            <div className="col-6 mb-2">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="label-select-product">Productos</InputLabel>
                <Select
                  id="select-product"
                  labelId="select-product"
                  name="product"
                  value={item.id !== '' ? item.id : '0'}
                  onChange={handlerCreateProduct}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="0">
                    <em>Seleccionar</em>
                  </MenuItem>
                  {
                    productsFilter.filter((p) => !stock.find((pStock) => p.id === pStock.id))
                      .map((cat) => (
                        <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>
                      ))
                  }
                </Select>
              </FormControl>
            </div>

            <div className="col-12 mb-1">
              <InputLabel id="frozen-label">
                Es un producto congelado?
                <Checkbox
                  htmlFor="frozen-label"
                  checked={item.isFrozen}
                  onChange={() => handlerCheckFrozenProduct()}
                />
              </InputLabel>
            </div>

            {item.isFrozen ? (
              <div className="col-12 mb-1">
                <TextField
                  variant="standard"
                  error={parseInt(item.amountFrozenProduct, 10) === 0 || item.amountFrozenProduct === '' || Number.isNaN(item.amountFrozenProduct)}
                  label="Cantidad en el congelador *"
                  className="w-100"
                  name="amountFrozenProduct"
                  value={item.amountFrozenProduct}
                  onChange={handleChange}
                />
              </div>
            ) : (
              <div className="col-12 mb-1">
                <TextField
                  variant="standard"
                  error={parseInt(item.amountStock, 10) === 0 || item.amountStock === '' || Number.isNaN(item.amountStock)}
                  label="Cantidad de Stock *"
                  className="w-100"
                  name="amountStock"
                  value={item.amountStock}
                  onChange={handleChange}
                />
              </div>
            )}

            <div className="col-12 mt-1 mb-1">
              <InputLabel>
                <small>
                  Imagen
                </small>
              </InputLabel>
            </div>
            <div className="col-12 mt-1 mb-3 text-center d-flex flex-column align-items-center">
              {item.photo !== '' && (
                <img
                  width="288"
                  height="216"
                  className="m-auto img-product"
                  src={item.photo}
                  alt={item.name}
                />
                )}
                {item.photo === '' && (
                  <Skeleton
                    variant="rect"
                    width={288}
                    height={216}
                  />
                )}
            </div>

            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerClose}>Cancelar</button>
            </div>
            <div className="col-6 mb-2">
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={handlerAdd}
                disabled={
                  item.id === ''
                  || item.name === ''
                  || item.category === ''
                  ||
                  (
                    (item.isFrozen &&
                      (item.amountFrozenProduct === '' ||
                        isNaN(item.amountFrozenProduct) ||
                        parseInt(item.amountFrozenProduct, 10) <= 0)) ||
                    (!item.isFrozen &&
                      (item.amountStock === '' ||
                        isNaN(item.amountStock) ||
                        parseInt(item.amountStock, 10) <= 0))
                  )
                }
              >
                Agregar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseDeleteItem(); } }}
      >
        <DialogTitle>
          Eliminar
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-3">
              ¿Seguro desea eliminar el producto?
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseDeleteItem}>Cancelar</button>
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-primary w-100" type="button" onClick={handlerDelete}>Eliminar</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Stock;
