import React, { forwardRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import QRCode from "react-qr-code";
import { urlpdf } from '../service';
import { numberToMoney, encodeData } from '../Cache/cacheStorage';

const BillToPrint = forwardRef((props, ref) => {
  const { company, transaction } = props;

  return (
    <div ref={ref} style={{paddingLeft: '0px', paddingRight: '0px', marginLeft: '0px', marginRight: '0px'}}>
      <div style={{fontFamily: 'monospace', backgroundColor: 'white', paddingLeft: '0px', paddingRight: '0px', marginLeft: '0px', marginRight: '0px', width: '100%', overflow: 'hidden'}}>
        <div style={{textAlign: 'center', paddingBottom: '0px'}}>
          <h2 style={{marginTop:'0px', marginBottom:'0px',}}>{company.companyName}</h2>
          <p style={{marginTop:'0px', marginBottom:'0px',}}>{company.branchOfficeName}</p>
          <p style={{marginTop:'0px', marginBottom:'0px',}}>DIR: {company.address}</p>
          <p style={{marginTop:'0px', marginBottom:'0px',}}>TEL: {company.phoneNumber}</p>
          <p style={{marginTop:'0px', marginBottom:'0px',}}>NIT: {company.nit}</p>
        </div>
        <div style={{height:'2px', backgroundColor: '#000', marginTop:'10px', marginBottom: '10px'}}></div>
        <div style={{paddingLeft:'0px', paddingRight:'0px',}}>
               <p style={{marginTop:'0px', marginBottom:'0px',}}>Empleado: {transaction.employeeName}</p>
          <p style={{marginTop:'0px', marginBottom:'0px',}}>Forma de pago: {transaction.paymentMethod}</p>
           <p style={{marginTop:'0px', marginBottom:'0px',}}>Fecha: {moment(transaction.createdate).format('DD/MM/YYYY')}</p>
            <p style={{marginTop:'0px', marginBottom:'0px',}}>Hora: {moment(transaction.createdate).format('hh:mm:ss')}</p>
        </div>
        <div style={{height:'2px', backgroundColor: '#000', marginTop:'10px', marginBottom: '5px'}}></div>
        <div style={{paddingLeft: '0px', paddingRight: '0px'}}>
          <p style={{marginTop:'5px', marginBottom: '5px',}}></p>
          <table style={{width: '100%'}}>
            <thead> 
              <tr>
                <th style={{textAlign: 'left', width: '60%',}}>Prod.</th>
                <th style={{textAlign: 'left', width: '20%',}}>Cant.</th>
                <th style={{textAlign: 'right', width: '20%',}}>Val.</th>
              </tr>
            </thead>
          </table>
          <table style={{width: '100%'}}>
            <tbody>
              {transaction.products.map((item) => (
                <tr key={item.id}>
                  <td style={{textAlign: 'left'}}>
                    {item.name}
                  </td>
                  <td style={{textAlign: 'left'}}>
                    {item.amount}
                  </td>
                  <td style={{textAlign: 'right'}}>
                    {numberToMoney(item.price)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <table style={{width: '100%'}}>
            <tfoot>
              <tr>
                <th style={{textAlign: 'left', width: '80%'}}>Total</th>
                <th style={{textAlign: 'right', width: '20%'}}>
                  {numberToMoney(transaction.totalAmount)}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div style={{height:'2px', backgroundColor: '#000', marginTop:'15px', marginBottom: '15px'}}></div>
        <div style={{textAlign: 'center', marginBottom:'10px'}}>
          <QRCode size={192} value={`${urlpdf}pdf?f=1&d=${encodeData({
            id: transaction.id,
            createdate: transaction.createdate,
            employeeName: transaction.employeeName,
            paymentMethod: transaction.paymentMethod,
            products: transaction.products.map(item => ({name: item.name, price: item.price, amount: item.amount})),
            totalAmount: transaction.totalAmount,
          })}&c=${encodeData({
            companyName: company.companyName,
            branchOfficeName: company.branchOfficeName,
            address: company.address,
            phoneNumber: company.phoneNumber,
            nit: company.nit,
          })}`}
          />
          <p style={{marginTop:'10px', marginBottom:'0px'}}>CUFE</p>
          <p style={{marginTop:'0px', marginBottom:'0px'}}>{transaction.id}</p>
          <p style={{marginTop:'0px', marginBottom:'0px'}}>{moment(transaction.createdate).format('DD/MM/YYYY hh:mm:ss')}</p>
        </div>
      </div>
    </div>
  );
});

BillToPrint.propTypes = {
  company: PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    branchOfficeName: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    nit: PropTypes.string.isRequired,
  }).isRequired,
  transaction: PropTypes.shape({
    id: PropTypes.string.isRequired,
    employeeName: PropTypes.string.isRequired,
    paymentMethod: PropTypes.string.isRequired,
    createdate: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
    products: PropTypes.array.isRequired,
  }).isRequired,
};

BillToPrint.displayName = "BillToPrint";
export default BillToPrint;
