import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import MaterialTable from '@material-table/core';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';

import { useSelector, useDispatch } from 'react-redux';
import {
  setRol, insertRol, updateRol, deleteRol,
} from '../reducers/rol';

import {
  getRequest, postRequest, putRequest, deleteRequest, getSubMenu, getLangUser,
} from '../Cache/cacheStorage';
import { labelLang } from '../Lang/lang';

function Materials() {
  const submenu = getSubMenu('settings', 'role');

  const apiUrl = 'role';
  const storedData = 'role';
  const [item, setItem] = useState({
    id: '', name: '', active: false,
  });
  const [modalItem, setmodalItem] = useState(false);
  const [deleteItem, setdeleteItem] = useState('');
  const [modalDelete, setmodalDelete] = useState(false);

  const [msmAlert, setmsmAlert] = useState('');
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);

  const data = useSelector((state) => state.rol.value);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  /* A function that is used to set the rol. */
  const handlerSetRol = (infoRol) => {
    dispatch(setRol(infoRol));
  };

  const handlerInsertRol = (infoRol) => {
    dispatch(insertRol(infoRol));
  };

  const handlerUpdateRol = (infoRol) => {
    dispatch(updateRol(infoRol));
  };

  const handlerDeleteRol = (infoRol) => {
    dispatch(deleteRol(infoRol));
  };

  const getRol = async () => {
    const requestRol = await getRequest(apiUrl, {}, storedData, false);
    handlerSetRol(requestRol.data.dataResponse);
  };

  useEffect(() => {
    if (data.length === 0) {
      getRol();
    }
  }, []);

  const handlerOpenModalItem = (id) => {
    if (id !== '') {
      const obj = data.filter((x) => x.id === id);
      setItem(obj[0]);
    }

    setmodalItem(true);
  };

  const handlerCloseModalItem = () => {
    setItem({
      id: '', name: '', active: false,
    });

    setmodalItem(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeStock = (e) => {
    const { name, checked } = e.target;
    setItem((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handlerItem = async (dataItem, method) => {
    if (method === 'POST') {
      const createData = await postRequest(apiUrl, dataItem, storedData, false);

      if (createData.status === 200) {
        handlerUpdateRol(dataItem);
        setItem({
          id: '', name: '', active: false,
        });
        setmodalItem(false);
        setmsmAlert('El Rol se ha creado exitosamente.');
        setalertSuccess(true);
      } else {
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }
    }

    if (method === 'PUT') {
      const updateData = await putRequest(apiUrl, dataItem, storedData, false);

      if (updateData.status === 200) {
        handlerInsertRol(dataItem);
        setItem({
          id: '', name: '', active: false,
        });
        setmodalItem(false);
        setmsmAlert('El Rol se ha actualizado exitosamente.');
        setalertSuccess(true);
      } else {
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }
    }
  };

  const handlerProcess = () => {
    const dataItem = item;
    let method = '';
    if (dataItem.id === '') {
      method = 'POST';
      dataItem.id = uuidv4();
    } else {
      method = 'PUT';
    }

    handlerItem(dataItem, method);
  };


  // #region Delete
  const handlerOpenModalDelete = (id) => {
    setdeleteItem(id);
    setmodalDelete((modal) => !modal);
  };

  const handlerCloseModalDelete = () => {
    setmodalDelete((modal) => !modal);
  };

  const handlerDelete = async () => {
    const deleteData = await deleteRequest(apiUrl, { id: deleteItem }, storedData, false);
    if (deleteData.status === 200) {
      if (deleteData.data.status === false) {
        setdeleteItem('');
        handlerCloseModalDelete();
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }

      if (deleteData.data.status === true) {
        handlerDeleteRol(deleteItem);
        setmsmAlert('Se ha eliminado con exito.');
        setalertSuccess(true);
        setdeleteItem('');
        setmodalDelete(false);
      }
    } else {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };
  // #endregion

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  return (
    <div className="h-100 px-3 bg-white btn-rounded inside-scroll-container">
      <MaterialTable
        title={<h3>Rol</h3>}
        columns={[
          {
            title: 'Nombre',
            field: 'name',
          },
        ]}
        data={data}
        actions={
          [
            {
              icon: 'edit',
              tooltip: 'Actualizar',
              onClick: (event, rowData) => handlerOpenModalItem(rowData.id),
              disabled: submenu.update !== true,
            },
            {
              icon: 'delete',
              tooltip: 'Eliminar',
              onClick: (event, rowData) => handlerOpenModalDelete(rowData.id),
              disabled: submenu.delete !== true,
            },
            {
              icon: 'add',
              tooltip: 'Crear',
              isFreeAction: true,
              onClick: () => handlerOpenModalItem(''),
              disabled: submenu.create !== true,
            },
          ]
        }
        options={{
          maxColumnSort: 'all_columns',
          paging: data.length > 100,
          pageSize: 100,
          pageSizeOptions: [100, 150, 200, 300],
        }}
        localization={{
          toolbar: {
            searchPlaceholder: labelLang(getLangUser(), 'table', 'searchPlaceholder'),
            searchTooltip: labelLang(getLangUser(), 'table', 'searchTooltip'),
          },
          header: {
            actions: labelLang(getLangUser(), 'table', 'actions'),
          },
          pagination: {
            labelRowsPerPage: labelLang(getLangUser(), 'table', 'labelRowsPerPage'),
            labelDisplayedRows: labelLang(getLangUser(), 'table', 'labelDisplayedRows'),
            labelRowsSelect: labelLang(getLangUser(), 'table', 'labelRowsSelect'),
            firstAriaLabel: labelLang(getLangUser(), 'table', 'firstAriaLabel'),
            firstTooltip: labelLang(getLangUser(), 'table', 'firstTooltip'),
            previousAriaLabel: labelLang(getLangUser(), 'table', 'previousAriaLabel'),
            previousTooltip: labelLang(getLangUser(), 'table', 'previousTooltip'),
            nextAriaLabel: labelLang(getLangUser(), 'table', 'nextAriaLabel'),
            nextTooltip: labelLang(getLangUser(), 'table', 'nextTooltip'),
            lastAriaLabel: labelLang(getLangUser(), 'table', 'lastAriaLabel'),
            lastTooltip: labelLang(getLangUser(), 'table', 'lastTooltip'),
          },
        }}
      />

      <Dialog
        open={modalItem}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalItem(); } }}
      >
        <DialogTitle>
          {item.id ? (
            <span>Editar</span>
          ) : (
            <span>Crear</span>
          )}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-4">
              <TextField
                variant="standard"
                error={item.name === ''}
                label="Nombre *"
                className="w-100"
                name="name"
                value={item.name}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 mb-4">
              <InputLabel id="label-update-materials">Activar</InputLabel>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="active"
                    checked={item.active}
                    onChange={handleChangeStock}
                  />
                )}
                label="Activar"
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalItem}>Cancelar</button>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={handlerProcess}
                disabled={item.name === ''}
              >
                Guardar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalDelete(); } }}
      >
        <DialogTitle>
          Eliminar
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-3">
              ¿Seguro desea eliminar el Rol?
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalDelete}>Cancelar</button>
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-primary w-100" type="button" onClick={handlerDelete}>Eliminar</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Materials;
