/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const tableTransaction = createSlice({
  name: 'tableTransaction',
  initialState: {
    value: {
      id: "",
      name: "",
      chairSelected: "",
      chairlist: [],
      allChairs: false
    },
  },
  reducers: {
    setTableData: (state, action) => {
      state.value.id = action.payload.id;
      state.value.name = action.payload.name;
      state.value.chairSelected = action.payload.chairSelected;
      state.value.chairlist = action.payload.chairlist;
      state.value.allChairs = action.payload.allChairs;
    },
    setResetTableData: (state, action) => {
      state.value.id = "";
      state.value.name = "";
      state.value.chairSelected = "";
      state.value.chairlist = [];
      state.value.allChairs = false;
    },
  }
});

export const { setTableData, setResetTableData } = tableTransaction.actions;

export default tableTransaction.reducer;
