import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import BuildIcon from '@mui/icons-material/Build';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import EmailIcon from '@mui/icons-material/Email';
import moment from 'moment';
// import PrintProvider, { NoPrint } from 'react-easy-print';
import Pool from '../Security/userPool';
import { MenuContainer } from './style';
import { useSelector, useDispatch } from 'react-redux';
import { updateProductReturnAmountToOrder } from '../reducers/stock';
import { resetTransaction } from '../reducers/transaction';
import {
  getMenu, getTypeUserData, getEmailUser, getRequest, getAuthUser, handlerSendEmail,
  decodeData, encodeData,
} from '../Cache/cacheStorage';

function Menu() {
  const history = useHistory();
  const menu = getMenu();
  const userData = getTypeUserData();
  const emailUser = getEmailUser();
  const authUserTest = getAuthUser();

  const dispatch = useDispatch();

  const transaction = useSelector((state) => state.transaction.value);
  
  const handlerUpdateProductReturnAmountToOrder = (infoStock) => {
    dispatch(updateProductReturnAmountToOrder(infoStock));
  };

  const handlerResetTransaction = () => {
    dispatch(resetTransaction());
  };

  const [currentView, setCurrentView] = useState(history.location.pathname.substring(1).split("/")[0]);

  const logout = async () => {
    const { email, lastLogin, logindate, pass, token } = JSON.parse(decodeData(sessionStorage.getItem('cogdatauser')));
    const { typeUser, typeUserName } = getTypeUserData();
    
    const objectUser = {
      email: email,
      lastLogin: lastLogin,
      logindate: logindate,
      pass: pass,
      token: token,
      typeUser: typeUser,
      typeUserName: typeUserName
    };

    const user = Pool.getCurrentUser();
    if (user) {
      const requestLogOut = await getRequest('log-out-user', encodeData(objectUser), 'log-out-user', false);
      if (requestLogOut.status === 200) {
        sessionStorage.clear();
        user.signOut();
        history.push('/');
        window.location.reload();
      }
    }
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  const [clickSendEmail, SetClickEmail] = useState(1);
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);
  const [msmAlert, setmsmAlert] = useState('');

  const handlerSendEmailResume = async () => {
    if (clickSendEmail !== 0 && clickSendEmail > 0) {      
      const emailResquest = await handlerSendEmail(moment().startOf('day').format('YYYY-MM-DD'));

      if (emailResquest.status === 200) {
        setmsmAlert('Email enviado');
        setalertSuccess(true);
      }

      SetClickEmail(clickSendEmail - 1);
    } else {
      setmsmAlert('Ya se ha enviado un email');
      setalertError(true);
    }
  };

  const handlerSetCurrenView = (e) => {
    if (currentView === 'new-order' || currentView === 'tables') {
      if (e !== 'new-order' || e !== 'tables' || e === 'tables') {
        const productsFromStock = transaction.products.filter((p) => p.fromStock === true);

        if (productsFromStock.length > 0) {
          for (let prodFromStock of productsFromStock) {
            const element = prodFromStock;

            handlerUpdateProductReturnAmountToOrder({
              id: element.id,
              currentAmount: 0,
              prevAmount: element.amount,
            });

          }
        }

        handlerResetTransaction();
      }
    }

    setCurrentView(e);
  }

  const menuIconMap = {
    'tables': <RestaurantIcon className="icon-menu" />,
    'new-order': <OpenInBrowserIcon className="icon-menu" />,
    'reports': <MultilineChartIcon className="icon-menu" />,
    'settings': <BuildIcon className="icon-menu" />,
    'clock-in': <PersonPinIcon className="icon-menu" />,
  };

  return (
    <div className="rounded-3">
      <>
        {authUserTest === 'true' && (
            <MenuContainer>
              {userData.typeUser === 'pppp-xqqx-xbbb-x2x1' || userData.typeUser === 'qaqa-bbbb-asfh-axvn' ? (
                menu.map((item) => (
                  <Link
                    key={item.menuUrl}
                    id={item.menuUrl}
                    onClick={() => {
                      handlerSetCurrenView(item.menuUrl)
                    }}
                    className={`nav-button text-decoration-none
                      ${item.menuUrl === 'tables' ? 'rounded-left' : ''} 
                      ${item.menuUrl === 'clock-in' ? 'rounded-right' : ''} 
                      ${item.menuUrl === currentView ? ' nav-bar-active' : ''}`
                    }
                    to={`/${item.menuUrl}`}
                  >
                    <span className="text-menu">
                      {item.tagMenu}
                    </span>
                    {menuIconMap[item.menuUrl]}
                  </Link>
                ))
              ) : (
                userData.typeUser === 'x2x2-x2x4-x4x5-444f' && (
                  <Link
                    key="settings"
                    id="settings"
                    onClick={() => setCurrentView('admin-view')}
                    className={`nav-button text-decoration-none rounded-left ${currentView === 'admin-view' ? ' nav-bar-active' : ''}`}
                    to="/admin-view"
                  >
                    <span className="text-menu">
                      Configuración
                    </span>
                    <BuildIcon
                      className="icon-menu"
                    />
                  </Link>
                )
              )}

              <Link
                onClick={() => logout()}
                className="nav-log-out text-decoration-none btn-logout rounded-right"
                to="/"
                style={{ height: '41px' }}
              >
                <p className="d-flex p-content-center">
                  <span
                    className="text-menu"
                  >
                    Cerrar Sesión &nbsp;
                  </span>
                  <ExitToAppIcon />
                </p>
              </Link>
              <button
                className="nav-button text-decoration-none btn-logout rounded-left"
                style={{ height: '41px' }}
                type="button"
                onClick={() => handlerSendEmailResume()}
              >
                <p className="d-flex p-content-center">
                  <small className="text-menu">
                    Enviar Email
                      &nbsp;{emailUser.email}
                  </small>
                  <EmailIcon className="icon-menu" />
                </p>
              </button>
            </MenuContainer>
        )}
      </>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Menu;
