/* eslint-disable */
import React, { useState } from 'react';
import moment from 'moment';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { grey, blue } from '@mui/material/colors';
import { useHistory } from 'react-router-dom';
import userPool from './userPool';
import {
  encode as base64_encode
} from 'base-64';
import { getRequest, postRequest } from '../Cache/cacheStorage';

const CssTextField = styled(TextField)({
  '& .MuiFormLabel-root': {
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '&& .MuiInput-root:hover::before': {
    borderColor: 'aqua',
  }
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(grey[50]),
  backgroundColor: grey[50],
  '&:hover': {
    backgroundColor: blue[500],
  },
}));

function Login() {
  const [msmAlert, setmsmAlert] = useState('');
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const setTokenUser = async () => {
    if (sessionStorage.getItem('cogauthuser') === "true") {
      const responseLogin = await postRequest('open-session-user', sessionStorage.getItem('cogdatauser'), 'open-session-user', false);
      if (responseLogin.status === 200) {
        const responseUser = await getRequest('session-user', sessionStorage.getItem('cogdatauser'), 'session-user', false);
        if (responseUser.status === 200) {
          sessionStorage.setItem('cogappuser', base64_encode(JSON.stringify(responseUser.data)));
          sessionStorage.setItem('lang', responseUser.data.lang);
          history.push('/');
          window.location.reload();
        }
      } else {
        setmsmAlert('Error, Usuario o Contraseña invalidos. Por favor, Intente de nuevo.');
        setalertError(true);
      }
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        const dataUser = {
          logindate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
          email: data.idToken.payload.email,
          pass: password,
          token: data.accessToken.jwtToken,
          lastLogin: moment().format('YYYY-MM-DDTHH:mm:ss.SSS')
        };

        if (data.idToken.payload.email === email){
          sessionStorage.setItem('cogdatauser', base64_encode(JSON.stringify(dataUser)));
          sessionStorage.setItem('cogauthuser', 'true');
          setTokenUser();
        } else {
          sessionStorage.setItem('cogauthuser', "true");
        }
      },
      onFailure: (err) => {
        setmsmAlert('Error, Usuario o Contraseña invalidos. Por favor, Intente de nuevo.');
        setalertError(true);
      },
      onPasswordRequired: (data) => {
        setmsmAlert('Error, valide sus credenciales de acceso con soporte.');
        setalertError(true);
      },
    });
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  return (
    <div className="w-100 h-100-i d-flex justify-content-center bg-security">
      <div className="d-flex justify-content-center m-auto p-4">
        <div className="main-container btn-rounded my-auto px-4 py-5 text-white text-white text-center d-flex flex-column">
          <h2
            className="mb-5"
            title="Version 9.0.0"
          >
              Iniciar Sesión
            </h2>
          <form onSubmit={onSubmit}>
            <CssTextField
              variant="standard"
              label="Correo"
              color="grey"
              className="w-100 mb-2"
              InputProps={{
                style: { color: 'white' },
                autoComplete: 'off',
              }}
              onChange={(event) => setEmail(event.target.value)}
            />
            <CssTextField
              variant="standard"
              label="Contraseña"
              className="w-100 mb-5"
              InputProps={{
                style: { color: 'white' },
                autoComplete: 'new-password',
              }}
              type="password"
              onChange={(event) => setPassword(event.target.value)}
            />
            <br />
            <ColorButton
              variant="contained"
              className="w-100"
              type="submit"
            >
              Ingresar
            </ColorButton>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Login;
