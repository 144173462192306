/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link, useHistory  } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  getRequest, postRequest, putRequest, deleteRequest, getListSubMenu,
} from '../Cache/cacheStorage';
import './style.scss';
import { useDispatch } from 'react-redux';
import { setTableData } from '../reducers/tableTransaction';

function Mesas() {
  const history = useHistory();

  const submenuList = getListSubMenu('tables');
  const [tables, settables] = useState([]);
  const [orderTables, setOrderTables] = useState([]);
  const [deleteItem, setdeleteItem] = useState({});
  const [msmAlert, setmsmAlert] = useState('');
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const handlerSetTableData = (infoTableTransaction) => {
    dispatch(setTableData(infoTableTransaction));
  };

  const getTables = async () => {
    const responseLogin = await getRequest('tables', {}, 'tables', false);
    if (responseLogin.status === 200) {
      settables(responseLogin.data.dataResponse);
      setOrderTables([...responseLogin.data.dataResponse]);
    }
  };

  useEffect(() => {
    getTables();
  }, []);

  const [nameNewTable, setNameNewTable] = useState("");

  const handlerCreateName = (e) => {
    setNameNewTable(e.target.value);
  };

  const [selectedOption, setSelectedOption] = useState("");

  const onChangeRadioButton = (e) => {
    setSelectedOption(e.target.value);
  };

  const generateChairsOfType = (count) => {
    const chairs = [];
    for (let i = 1; i <= count; i++) {
      chairs.push({
        id: i,
        currentTransaction: '',
      });
    }
    return chairs;
  };

  const generateChairs = (selectedOption) => {
    switch (selectedOption) {
      case 'mesa-type-1':
      case 'mesa-type-1-1':
        return generateChairsOfType(2);
      case 'mesa-type-2':
        return generateChairsOfType(4);
      case 'mesa-type-3':
        return generateChairsOfType(6);
      default:
        return [];
    }
  };

  const handlerCreateTable = async () => {
    const item = {
      branchOffice: '',
      id: uuidv4(),
      chairs: generateChairs(selectedOption),
      class: selectedOption,
      currentTransaction: '',
      name: nameNewTable,
      numberChairs: (generateChairs(selectedOption).length + 1),
      position_y: 0,
      position_x: 0,
      state: 0,
      order: (tables.length + 1)
    };

    const response = postRequest('new-table', item, 'tables', false);
    if (response.status !== 200) {
      setmsmAlert('La mesas se ha creado exitosamente.');
      setalertSuccess(true);

      settables([...tables, item]);
      handlerCloseModalItem();
    } else {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  const handlerGetTransactionId = (table, chair) => {
    let urlTransaction = '';
    const idTransactionfromChair = table.chairs.find((x) => x.id === chair).currentTransaction;

    if (idTransactionfromChair !== '' && idTransactionfromChair !== undefined && idTransactionfromChair !== null) {
      urlTransaction = `&transaction=${idTransactionfromChair}`;
    }

    return urlTransaction;
  };

  const handlerGetLink = (table, chair) => {
    const idTransactionfromChair = table.chairs.find((x) => x.id === chair).currentTransaction;
    const linkTo = `/new-order?idMesa=${table.id}&idChair=${chair}${idTransactionfromChair !== "" ? handlerGetTransactionId(table, chair) : ''}`;

    return linkTo;
  };

  const handlerGetClassForMesaState = (state) => {
    let className = '';
    if (state === 0) {
      className = 'bg-mesa-pay-order';
    } else if (state === 1) {
      className = 'bg-mesa-taking-order';
    } else if (state === 2) {
      className = 'bg-mesa-preparing-order';
    } else {
      className = 'bg-mesa-without-order';
    }
    return className;
  };

  /*
  const [isEditTables, setIsEditTables] = useState(false);

  const handlerGetClass = (type, table) => {
    const validatorEdit = type === 1 ? true : false;
    const classBtn = `w-100 h-100 vertical-center p-2 ${isEditTables === validatorEdit ? 'd-none' : ''} ${handlerGetClassForMesaState(table.state)}`;
    return classBtn;
  };
  */

  /* New Code */

  const [modalItem, setModalItem] = useState(false);

  const handlerOpenModalItem = () => {
    setModalItem(true);
  };

  const handlerCloseModalItem = () => {
    setModalItem(false);
  };

  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [tableEdit, setTableEdit] = useState({
    id: '',
    name: ''
  });

  const handleContextMenu = (e, data) => {
    e.preventDefault();

    const itemTable = tables.find((x) => x.id === data.id);
    setTableEdit(itemTable);

    setContextMenuOpen(true);
  };

  const closeContextMenu = () => {
    setContextMenuOpen(false);
  };

  const handleNameChange = (e) => {
    setTableEdit({
      ...tableEdit,
      name: e.target.value
    });
  };

  const updateMesa = async () => {
    const response = await putRequest('tables', tableEdit, 'tables', false);

    if (response.status !== 200) {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    } else {
      setmsmAlert('Se ha actualizado con exito.');
      setalertSuccess(true);
      getTables();
    }

    closeContextMenu();
  };

  const [viewTableDelete, setViewTableDelete] = useState(false);

  const handlerShowViewDeleteTable = () => {
    setViewTableDelete(tableEdit);
    setViewTableDelete(true);
  };

  const handlerHideViewDeleteTable = () => {
    setViewTableDelete(false);
  };

  const handlerDeleteTable = async () => {
    const responseDelete = await deleteRequest('tables', tableEdit, 'tables', false);
    if (responseDelete.status === 200) {
      if (responseDelete.data.dataResponse.status === true) {
        setdeleteItem({});
        getTables();
        closeContextMenu();
        setmsmAlert('Se ha eliminado con exito.');
        setalertSuccess(true);
      }

      if (responseDelete.data.dataResponse.status === false) {
        setdeleteItem({});
        closeContextMenu();
        setmsmAlert('Error, por favor intente de nuevo.');
        setalertError(true);
      }
    }
  };

  const [modalNewOrder, setModalNewOrder] = useState(false);
  const [tableTransaction, setTableTransaction] = useState({
    branchOffice: "",
    id: "",
    active: true,
    chairs: [],
    class: "",
    currentTransaction: "",
    name: "",
    numberChairs: 0,
    position_x: 0,
    position_y: 0,
    state: 0,
    order: 0
  });
  const [chairSelectedForNewOrder, setChairSelectedForNewOrder] = useState(0);
  const [chairListSelectedForNewOrder, setChairListSelectedForNewOrder] = useState([]);

  const handlerShowModalNewOrder = () => {
    setModalNewOrder(true);
  };

  console.log(chairSelectedForNewOrder);

  const handlerPreOrder = (table, chairId) => {
    setTableTransaction(table);
    setChairSelectedForNewOrder(chairId);
    handlerAddMoreChairs(chairId);

    handlerShowModalNewOrder();
  };

  const handlerAddMoreChairs = (newID) => {
    setChairListSelectedForNewOrder((prevList) => {
      if (prevList.includes(newID)) {
        return prevList.filter((id) => id !== newID);
      } else {
        return [...prevList, newID];
      }
    });
  };

  const handlerGotoNewOrder = () => {
    const generatedLink = handlerGetLink(tableTransaction, chairSelectedForNewOrder);
    history.push(generatedLink);
  };

  const obtenerIdsDeSillas = (data) => {
    if (Array.isArray(data.chairs) && data.chairs.length > 0) {
      const ids = data.chairs.map(silla => silla.id);
      return ids;
    } else {
      return [];
    }
  }

  const handleProcessTransactionAllChairs = () => {
    const tableDataTransaction = {
      id: tableTransaction.id,
      name: tableTransaction.name,
      chairSelected: chairSelectedForNewOrder,
      chairlist: obtenerIdsDeSillas(tableTransaction),
      allChairs: true
    };
    handlerSetTableData(tableDataTransaction);

    handlerGotoNewOrder();
  };

  const areAllChairsSelected = () => {
    const { chairs } = tableTransaction;
    const initialChairId = chairSelectedForNewOrder;
    const filteredChairs = chairs.filter(chair => chair.id !== initialChairId);

    return filteredChairs.every(chair => chairListSelectedForNewOrder.includes(chair.id));
  };

  const handleProcessTransactionChairsSelected = () => {
    const tableDataTransaction = {
      id: tableTransaction.id,
      name: tableTransaction.name,
      chairSelected: chairSelectedForNewOrder,
      chairlist: chairListSelectedForNewOrder,
      allChairs: areAllChairsSelected()
    };
    handlerSetTableData(tableDataTransaction);
    
    handlerGotoNewOrder();
  };

  const handlerHideViewModalNewOrder = () => {
    setModalNewOrder(false);
    setTableTransaction({
      branchOffice: "",
      id: "",
      active: true,
      chairs: [],
      class: "",
      currentTransaction: "",
      name: "",
      numberChairs: 0,
      position_x: 0,
      position_y: 0,
      state: 0,
      order: 0
    });
    setChairSelectedForNewOrder(0);
    setChairListSelectedForNewOrder([]);
  };

  const [modalOrderPriority, setModalOrderPriority] = useState(false);

  const handlerOpenModalOrderPriority = () => {
    setModalOrderPriority(true);
  };

  const handlerCloseModalOrderPriority = () => {
    setModalOrderPriority(false);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;
    const updatedCategoriesOrder = Array.from(orderTables);

    const [movedCategory] = updatedCategoriesOrder.splice(source.index, 1);
    updatedCategoriesOrder.splice(destination.index, 0, movedCategory);

    const updatedCategories = updatedCategoriesOrder.map((category, index) => ({
      ...category,
      order: index + 1,
    }));

    setOrderTables(updatedCategories);
  };

  const handlerUpdateOrder = async () => {
    try {
      for (let index of orderTables) {
        const requestUpdateOrder = await putRequest('order-tables', index, 'tables', false);
        if (requestUpdateOrder.status !== 200) {
          setmsmAlert('Error, por favor intente de nuevo.');
          setalertError(true);
          break;
        }
      }

      // handlerSetTableData(orderTables);
      getTables();

      setmsmAlert('La Prioridad se ha actualizado exitosamente.');
      setalertSuccess(true);
      setModalOrderPriority(false);
    } catch (error) {
      console.log(error);
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };

  return (
    <div className="w-100 mt-4 p-3 btn-rounded main-container">
      <Dialog
        open={modalItem}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalItem(); } }}
      >
        <DialogTitle>
          <span>Crear Mesa</span>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <TextField
                variant="standard"
                error={nameNewTable === ''}
                id="name"
                label="Nombre *"
                className="w-100"
                onChange={(e) => handlerCreateName(e)}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
              <span className="text-muted">Número de sillas</span>
              <div className="body-create-table">
                <label>
                  <input
                    type="radio"
                    name="type-table"
                    value="mesa-type-1"
                    onChange={(e) => onChangeRadioButton(e)}
                    checked={selectedOption === 'mesa-type-1'}
                  />
                  <div className="mesa-type-1">
                    <div className="table">2 Sillas abajo</div>
                    <div className="silla-1"></div>
                    <div className="silla-2"></div>
                  </div>
                </label>
                <label>
                  <input
                    type="radio"
                    name="type-table"
                    value="mesa-type-1-1"
                    onChange={(e) => onChangeRadioButton(e)}
                    checked={selectedOption === 'mesa-type-1-1'}
                  />                
                  <div className="mesa-type-1-1">
                    <div className="table">2 Sillas arriba</div>
                    <div className="silla-1"></div>
                    <div className="silla-2"></div>
                  </div>
                </label>
                <label>
                  <input
                    type="radio"
                    name="type-table"
                    value="mesa-type-2"
                    onChange={(e) => onChangeRadioButton(e)}
                    checked={selectedOption === 'mesa-type-2'}
                  />
                  <div className="mesa-type-2">
                    <div className="table">4 Sillas</div>
                    <div className="silla-1"></div>
                    <div className="silla-2"></div>
                    <div className="silla-3"></div>
                    <div className="silla-4"></div>
                  </div>
                </label>
                <label>
                  <input
                    type="radio"
                    name="type-table"
                    value="mesa-type-3"
                    onChange={(e) => onChangeRadioButton(e)}
                    checked={selectedOption === 'mesa-type-3'}
                  />
                  <div className="mesa-type-3">
                    <div className="table">6 Sillas</div>
                    <div className="silla-1"></div>
                    <div className="silla-2"></div>
                    <div className="silla-3"></div>
                    <div className="silla-4"></div>
                    <div className="silla-5"></div>
                    <div className="silla-6"></div>
                  </div>
                </label>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button
                className="btn btn-danger w-100"
                type="button"
                onClick={handlerCloseModalItem}
              >
                  Cancelar
              </button>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button
                className="btn btn-primary w-100"
                type="button"
                disabled={selectedOption === "" || nameNewTable === ""}
                onClick={handlerCreateTable}
              >
                Guardar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalOrderPriority}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalOrderPriority(); } }}
      >
        <DialogTitle>
          Prioridad
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2 modal-priority">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {orderTables.map((category, index) => (
                      <Draggable key={category.id} draggableId={category.id.toString()} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="drag-handle draggable-item"
                          >
                            {category.name}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalOrderPriority}>Cancelar</button>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button className="btn btn-primary w-100" type="button" onClick={handlerUpdateOrder}>Guardar</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      
      <Dialog
        open={modalNewOrder}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerHideViewModalNewOrder(); } }}
      >
        <DialogTitle>
          Crear orden
        </DialogTitle>
        <DialogContent>

          <div className="row">
            <div className="col-12 mb-4">

              <small className='text-muted'>
                <p>
                  * Si quieres crear una orden para toda la mesa, haz clic en &apos;Toda la mesa&apos;
                </p>
                <p>
                  * Si quieres crear una orden para una&#40;s&#41; mesa&#40;s&#41;, selecciona las silla&#40;s&#41; que necesites y haz clic en &apos;Crear orden&apos;
                </p>
              </small>
            </div>
            <div className="col-12 mb-5">
              <div className="p-5" style={{ textAlign: '-webkit-center' }}>
                <div className={`${tableTransaction.class}`} key={tableTransaction.id}>
                  <div className="table">
                    {tableTransaction.name}
                  </div>
                  {tableTransaction.chairs.map((chair) => (
                    <div
                      key={chair.id}
                      className={`silla-${chair.id} ${chair.id === chairSelectedForNewOrder ? 'bg-grey' : ''} ${chair.state === 1 ? 'bg-status-1' : ''} ${chair.state === 2 ? 'bg-status-2' : ''} ${chairListSelectedForNewOrder.includes(chair.id) ? 'bg-grey' : ''}`}
                      onClick={() => handlerAddMoreChairs(chair.id)}
                      disabled={chair.id === chairSelectedForNewOrder || chair.currentTransaction !== ""}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 mb-3">
              <button
                className="btn btn-primary w-100"
                onClick={() => handleProcessTransactionAllChairs()}
              >
                Toda la mesa
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-danger w-100"
                onClick={() => handlerHideViewModalNewOrder()}
              >
                Cancelar
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-success w-100"
                onClick={() => handleProcessTransactionChairsSelected()}
              >
                Crear orden
              </button>
            </div>
          </div>

        </DialogContent>
      </Dialog>

      <Dialog
        open={contextMenuOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { closeContextMenu(); } }}
      >
        <DialogTitle>
          <div className="d-flex justify-content-between">
            Editar Mesa
            {!viewTableDelete && (
              <button
                className="btn p-0"
                onClick={() => handlerShowViewDeleteTable()}
              >
                <DeleteIcon className="text-danger" />
              </button>
            )}
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              {viewTableDelete ? (
                <span>
                  ¿Seguro que quieres eliminar esta Mesa?
                </span>
              ) : (
                <TextField
                  variant="standard"
                  id={tableEdit.id}
                  className="w-100"
                  defaultValue={tableEdit.name}
                  error={tableEdit.name === ""}
                  onChange={(e) => handleNameChange(e)}
                />
              )}
            </div>
            {viewTableDelete ? (
              <>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <button className="btn btn-danger w-100" type="button" onClick={() => handlerHideViewDeleteTable()}>Cancelar</button>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <button className="btn btn-primary w-100" type="button" onClick={() => handlerDeleteTable()}>Eliminar</button>
                </div>
              </>
            ) : (
              <>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <button className="btn btn-danger w-100" type="button" onClick={closeContextMenu}>Cancelar</button>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <button className="btn btn-primary w-100" type="button" onClick={updateMesa}>Guardar</button>
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>

      {submenuList.menuUrl !== '' && (
        <div className="w-100 h-100">
          <div className="ps-2 h-100">
            <div className="w-100">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <h3 className="text-white w-100">
                    Mesas{' '}
                    <button
                      className="btn p-0 btn-add-table"
                      type="button"
                      onClick={() => handlerOpenModalItem()}
                    >
                      <AddIcon />
                    </button>
                    {' '}
                    <button
                      className="btn p-0 btn-add-table"
                      type="button"
                      onClick={() => handlerOpenModalOrderPriority()}
                    >
                      <FormatListBulletedIcon />
                    </button>
                    
                    <small className="float-end mx-2">
                      <h6 className="text-white">
                        <span className="bg-mesa-pay-order me-2">{''}</span>
                        {''}Disponible
                      </h6>
                    </small>
                    <small className="text-secondary float-end mx-2">
                      <h6 className="text-white">
                        <span className="bg-mesa-taking-order me-2">{''}</span>
                        No disponible{''}
                        <span className="text-menu">
                          (Silla con pedido no pago)
                        </span>
                      </h6>
                    </small>
                    <small className="text-secondary float-end mx-2">
                      <h6 className="text-white">
                        <span className="bg-mesa-preparing-order me-2">{''}</span>
                        Silla con pedido Pago{''}
                        <span className="text-menu">
                          (No disponible)
                        </span>
                      </h6>
                    </small>
                  </h3>
                </div>
              </div>
            </div>
            <div className="body-tables">
              {console.log(tables)}
              {tables && tables.sort((a, b) => a.order - b.order).map((mesa) => (
                <div className={`${mesa.class}`} key={mesa.id}>
                  <div
                    className="table"
                    onContextMenu={(e) => handleContextMenu(e, { id: mesa.id, name: mesa.name })}>
                    {mesa.name}
                  </div>
                  {mesa.chairs.map((chair) => (
                    chair.currentTransaction === "" ? (
                      <div
                        key={chair.id}
                        className={`silla-${chair.id}`}
                        onClick={() => handlerPreOrder(mesa, chair.id)}
                      />
                    ) : (
                      <Link
                        key={chair.id}
                        className={`silla-${chair.id} ${handlerGetClassForMesaState(chair.state)}`}
                        to={handlerGetLink(mesa, chair.id)}
                      />
                    )
                  ))}
                </div>
              ))}
            </div>  
          </div>
        </div>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Mesas;
