/* eslint-disable */
import React, { useState, useEffect } from 'react';
import MaterialTable from '@material-table/core';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  getRequest, postRequest, putRequest, deleteRequest, getLangUser,
} from '../Cache/cacheStorage';
import { labelLang } from '../Lang/lang';
import userPool from '../Security/userPool';

function Users() {
  const apiUrl = 'logIn';
  const storedData = 'logIn';
  const [data, setdata] = useState([]);
  const [item, setItem] = useState({
    email: '',
    password: '',
    typeUserName: '',
    companyName: '',
    branchOfficeName: '',
    lastLogin: '',
    branchOffice: '',
    active: false,
    token: '',
    typeUser: '',
    company: '',
    roleAccess: {},
    phoneNumber: '',
    address: '',
    name: '',
    lastName: '',
  });
  const [modalValidateUser, setModalValidateUser] = useState(false);
  const [modalItem, setmodalItem] = useState(false);
  const [deleteItem, setdeleteItem] = useState('');
  const [modalDelete, setmodalDelete] = useState(false);

  const [msmAlert, setmsmAlert] = useState('');
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);

  const getUsers = async () => {
    const requestgetUsers = await getRequest(apiUrl, {}, storedData, false);
    setdata(requestgetUsers.data.dataResponse);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handlerCloseValidateUser = () => {
    setItem({
      email: '',
      password: '',
      typeUserName: '',
      companyName: '',
      branchOfficeName: '',
      lastLogin: '',
      branchOffice: '',
      active: false,
      token: '',
      typeUser: '',
      company: '',
      roleAccess: {},
    });

    setModalValidateUser(false);
  };

  const handlervalidateUser = (email) => {
    const user = data.find((x) => x.email === email);
    setItem(user);

    setModalValidateUser(true);
  };

  const updateStateUser = async () => {
    const updateState = await putRequest('update-state-user', item, 'update-state-user', false);
    if (updateState.status === 200) {
      const emailVerified = await postRequest('enable-email-user', item, 'enable-email-user', false);
      if (emailVerified.status === 200) { 
        getUsers();
        setmsmAlert('Usuario registrado a falta de validación en su correo.');
        setalertSuccess(true);
      } else {
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);  
      }
    } else {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };

  const validateUser = async () => {
    userPool.signUp(item.email, item.password, [], null, (err, data) => {
      if (!err) {
        // console.log(data);
        updateStateUser();
      } else {
        // console.log(err);
        setmsmAlert('Error en el registro');
        setalertError(true);
      }
    });

    setModalValidateUser(false);
  };

  const handlerOpenModalDelete = (email) => {
    const userDelete = data.find((x) => x.email === email);
    setdeleteItem(userDelete);

    setmodalDelete((modal) => !modal);
  };

  const handlerCloseModalDelete = () => {
    setmodalDelete((modal) => !modal);
  };

  const deleteUser = async () => {
    /*
    const deleteData = await deleteRequest('delete-users', { email: deleteItem.email, password: deleteItem.password }, 'delete-users', false);
    if (deleteData.status === 200) {
      if (deleteData.data.status === false) {
        setdeleteItem('');
        handlerCloseModalDelete(false);
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }

      if (deleteData.data.status === true) {
        setmsmAlert('Se ha eliminado con exito.');
        setalertSuccess(true);
        setdeleteItem('');
        setmodalDelete(false);
      }
    } else {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
    */
  }; 

  const handlerDelete = async () => {
    // console.log(deleteItem);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  return (
    <div className="h-100 px-3 bg-white btn-rounded inside-scroll-container">
      <MaterialTable
        title="Usuarios"
        columns={[
          {
            title: 'Email',
            field: 'email',
          },
          {
            title: 'Compañia',
            field: 'companyName',
          },
          {
            title: 'Sucursal',
            field: 'branchOfficeName',
          },
          {
            title: 'Activo',
            field: 'active',
            render: (rowData) => (rowData.active === true ? 'Si' : 'No'),
          },
        ]}
        data={data}
        actions={
          [
            (rowdata) => ({
              icon: 'login',
              tooltip: 'Validar Usuario',
              hidden: rowdata.active === true,
              onClick: (event, rowData) => handlervalidateUser(rowData.email),
            }),
            (rowdata) => ({
              icon: 'delete',
              tooltip: 'Eliminar Usuario',
              hidden: rowdata.active === false,
              onClick: (event, rowData) => handlerOpenModalDelete(rowData.email),
            }),
          ]
        }
        options={{
          maxColumnSort: 'all_columns',
          paging: data.length > 100,
          pageSize: 100,
          pageSizeOptions: [100, 150, 200, 300],
          rowStyle: (rowData) => {
            if (rowData.active === false) {
              return { backgroundColor: '#fa343e', color: '#fff' };
            }
          },
        }}
        localization={{
          toolbar: {
            searchPlaceholder: labelLang(getLangUser(), 'table', 'searchPlaceholder'),
            searchTooltip: labelLang(getLangUser(), 'table', 'searchTooltip'),
          },
          header: {
            actions: labelLang(getLangUser(), 'table', 'actions'),
          },
          pagination: {
            labelRowsPerPage: labelLang(getLangUser(), 'table', 'labelRowsPerPage'),
            labelDisplayedRows: labelLang(getLangUser(), 'table', 'labelDisplayedRows'),
            labelRowsSelect: labelLang(getLangUser(), 'table', 'labelRowsSelect'),
            firstAriaLabel: labelLang(getLangUser(), 'table', 'firstAriaLabel'),
            firstTooltip: labelLang(getLangUser(), 'table', 'firstTooltip'),
            previousAriaLabel: labelLang(getLangUser(), 'table', 'previousAriaLabel'),
            previousTooltip: labelLang(getLangUser(), 'table', 'previousTooltip'),
            nextAriaLabel: labelLang(getLangUser(), 'table', 'nextAriaLabel'),
            nextTooltip: labelLang(getLangUser(), 'table', 'nextTooltip'),
            lastAriaLabel: labelLang(getLangUser(), 'table', 'lastAriaLabel'),
            lastTooltip: labelLang(getLangUser(), 'table', 'lastTooltip'),
          },
        }}
      />

      <Dialog
        open={modalValidateUser}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseValidateUser(); } }}
      >
        <DialogTitle>
          Eliminar
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-3">
              ¿Seguro desea Validar este Usuario?
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseValidateUser}>Cancelar</button>
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-primary w-100" type="button" onClick={validateUser}>Validar</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalDelete(); } }}
      >
        <DialogTitle>
          Eliminar
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-3">
              ¿Seguro desea Eliminar este Usuario?
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalDelete}>Cancelar</button>
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-primary w-100" type="button" onClick={handlerDelete}>Eliminar</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Users;
