import React, { forwardRef } from 'react';
import moment from 'moment';
import { Buffer } from 'buffer';
import PropTypes from 'prop-types';

const BillToPrint = forwardRef((props, ref) => {
  const { f, c, d } = props;

  return (
    <div ref={ref}>
      <div className="bg-white px-4 py-4">
        <div className="d-block view-transaction" style={{ margin: '0 auto' }}>
          {f === '1' && (<div style={{fontFamily: 'monospace', backgroundColor: 'white', paddingLeft: '0px', paddingRight: '0px', marginLeft: '0px', marginRight: '0px', overflow: 'hidden'}}>
            <div style={{textAlign: 'center', paddingBottom: '5px'}}>
              <h2 style={{marginTop:'0px', marginBottom:'10px',}}>{JSON.parse(Buffer.from(c, 'base64').toString('utf8')).companyName}</h2>
              <p style={{marginTop:'5px', marginBottom:'5px',}}>{JSON.parse(Buffer.from(c, 'base64').toString('utf8')).branchOfficeName}</p>
              <p style={{marginTop:'5px', marginBottom:'5px',}}>DIR: {JSON.parse(Buffer.from(c, 'base64').toString('utf8')).address}</p>
              <p style={{marginTop:'5px', marginBottom:'5px',}}>TEL: {JSON.parse(Buffer.from(c, 'base64').toString('utf8')).phoneNumber}</p>
              <p style={{marginTop:'5px', marginBottom:'5px',}}>NIT: {JSON.parse(Buffer.from(c, 'base64').toString('utf8')).nit}</p>
            </div>
            <div style={{height:'2px', backgroundColor: '#FFF', marginTop:'10px', marginBottom: '10px'}}></div>
            <div style={{paddingLeft:'0px', paddingRight:'0px',}}>
              <p style={{marginTop:'5px', marginBottom:'5px',}}>Empleado: {JSON.parse(Buffer.from(d, 'base64').toString('utf8')).employeeName}</p>
              <p style={{marginTop:'5px', marginBottom:'5px',}}>Forma de pago: {JSON.parse(Buffer.from(d, 'base64').toString('utf8')).paymentMethod}</p>
              <p style={{marginTop:'5px', marginBottom:'5px',}}>Fecha: {moment(JSON.parse(Buffer.from(d, 'base64').toString('utf8')).createdate).format('DD/MM/YYYY')}</p>
              <p style={{marginTop:'5px', marginBottom:'5px',}}>Hora: {moment(JSON.parse(Buffer.from(d, 'base64').toString('utf8')).createdate).format('hh:mm:ss')}</p>
            </div>
            <div style={{height:'2px', backgroundColor: '#FFF', marginTop:'15px', marginBottom: '15px'}}></div>
            <div style={{paddingLeft: '0px', paddingRight: '0px'}}>
              <p style={{marginTop:'5px', marginBottom: '5px',}}></p>
              <table style={{width: '100%'}}>
                <thead> 
                  <tr>
                    <th style={{textAlign: 'left', width: '60%',}}>Prod.</th>
                    <th style={{textAlign: 'left', width: '20%',}}>Cant.</th>
                    <th style={{textAlign: 'right', width: '20%',}}>Val.</th>
                  </tr>
                </thead>
              </table>
              <table style={{width: '100%'}}>
                <tbody>
                  {JSON.parse(Buffer.from(d, 'base64').toString('utf8')).products.map((item, i) => (
                    <tr key={i}>
                      <td style={{textAlign: 'left'}}>
                        {item.name}
                      </td>
                      <td style={{textAlign: 'left'}}>
                        {item.amount}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        {Intl.NumberFormat('es-CO', {
                          style: 'currency',
                          currency: 'COP',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(item.price)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table style={{width: '100%'}}>
                <tfoot>
                  <tr>
                    <th style={{textAlign: 'left', width: '80%'}}>Total</th>
                    <th style={{textAlign: 'right', width: '20%'}}>
                      {Intl.NumberFormat('es-CO', {
                        style: 'currency',
                        currency: 'COP',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(JSON.parse(Buffer.from(d, 'base64').toString('utf8')).totalAmount)}
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div style={{height:'2px', backgroundColor: '#FFF', marginTop:'15px', marginBottom: '15px'}}></div>
            <div style={{textAlign: 'center', marginBottom:'10px'}}>
              <p style={{marginTop:'15px', marginBottom:'5px'}}>CUFE</p>
              <p style={{marginTop:'5px', marginBottom:'5px'}}>{JSON.parse(Buffer.from(d, 'base64').toString('utf8')).id}</p>
              <p style={{marginTop:'5px', marginBottom:'5px'}}>{moment(JSON.parse(Buffer.from(d, 'base64').toString('utf8')).createdate).format('DD/MM/YYYY hh:mm:ss')}</p>
            </div>
          </div>)}
        </div>
      </div>
    </div>
  );
});


BillToPrint.propTypes = {
  f: PropTypes.string.isRequired,
  c: PropTypes.string.isRequired,
  d: PropTypes.string.isRequired,
};


BillToPrint.displayName = "BillToPrint";
export default BillToPrint;