import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useHistory } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useReactToPrint } from 'react-to-print';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import BillToPrint from '../sharedComponents/billToPrint';
import { useSelector, useDispatch } from 'react-redux';
import { setEmployees } from '../reducers/employees';
import { setStock, updateProductForAddToOrder, updateProductReturnAmountToOrder, resetStock} from '../reducers/stock';
import {
  resetTransaction,
  setProductInTransactions,
  setAmountToProductInTransaction,
  setRemoveProductInTransaction,
} from '../reducers/transaction';
import { setProducts } from '../reducers/products';
import { setCategory } from '../reducers/category';
import { startLoading, stopLoading } from '../reducers/load'; 
import { setTableData } from '../reducers/tableTransaction';
import { getRequest, postRequest, putRequest, getCompany, numberToMoney } from '../Cache/cacheStorage';
import { isNumeric } from '../service';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CssTextField = styled(TextField)({
  '& .MuiFormLabel-root': {
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '&& .MuiInput-root:hover::before': {
    borderColor: 'aqua',
  }
});

function NuevaOrden() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const componentToPrint = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentToPrint.current,
  });

  const history = useHistory();
  const query = useQuery();

  const tableDataTransaction = useSelector((state) => state.tableTransaction.value);

  const arr = Array.from(Array(51), (_, x) => x);
  arr.shift();

  const [openModalStock, showModalStock] = useState(false);
  const [editModalStock, seteditModalStock] = useState(false);
  const [amountList, setAmountList] = useState([1]);
  const [updateStock, setUpdateStock] = useState(false);

  const [itemOrder, setItemOrder] = useState({
    id: '', name: '', category: '', categoryName: '', materials: [], photo: '', price: '', discountPercent: 0,
  });

  const [tables, settables] = useState([]);
  const [paymentMethodList, setpaymentMethodList] = useState([]);
  const [order, setOrder] = useState([]);

  const [openModal, showModal] = useState(false);
  const [editModal, seteditModal] = useState(false);

  const [categoryFilter, setCategoryFilter] = useState('0');
  const [filterProductName, setFilterProductName] = useState('');
  const [editProduct, seteditProduct] = useState({});
  const [currentAmount, setcurrentAmount] = useState(1);
  const [currentEditAmount, setcurrentEditAmount] = useState(1);

  const [priceProduct, setpriceProduct] = useState(0);
  const [priceEditProduct, setpriceEditProduct] = useState(0);

  const [processBtn, setprocessBtn] = useState(true);

  const [modalMetododePago, setmodalMetododePago] = useState(false);
  const [paymentMethodView, setpaymentMethodView] = useState(false);
  const [exchangeView, setexchangeView] = useState(true);
  const [exchangeMoney, setexchangeMoney] = useState(0);
  const [exchangeMoneyClient, setchangeMoneyClient] = useState(0);
  const [onlinePaymentView, setOnlinePaymentView] = useState(false);
  const [splitPaymentView, setSplitPaymentView] = useState(false);

  const [editTotalTransaction, seteditTotalTransaction] = useState(false);
  const [totalAmountEdit, settotalAmountEdit] = useState(0);
  const [commentTransaction, setcommentTransaction] = useState('');

  const [btnFinish, setbtnFinish] = useState(true);

  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);
  const [msmAlert, setmsmAlert] = useState('');

  const [transaction, setTransaction] = useState({
    id: '',
    createdate: '',
    employee: '',
    employeeName: '',
    paymentMethod: '',
    products: [],
    totalAmount: 0,
    table: 'pedido',
    tableName: 'pedido',
    comments: [],
    isEdit: false,
    stateTransaction: 0,
    tableData: [],
    isSplitPayment: false,
    splitPayment: []
  });

  const [newComment, setNewComment] = useState({
    emailRegister: '',
    createdate: '',
    comment: '',
  });

  const [anyNoValue, setAnyNoValue] = useState(false);
  const [modalAlertNoEdit, setmodalAlertNoEdit] = useState(false);

  // #region REDUX
  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  const transactionRedux = useSelector((state) => state.transaction.value);

  // #region LOADING
  const handlerStartLoading = () => {
    dispatch(startLoading());
  };

  const handlerStopLoading = () => {
    dispatch(stopLoading());
  };
  // #endregion

  // #region TRANSACTION
  const handlerResetTransaction = () => {
    dispatch(resetTransaction());
  };
  // #endregion

  // #region STOCK
  const stock = useSelector((state) => state.stock.value);

  const handlerSetRemoveProductInTransaction = (infoOrder) => {
    dispatch(setRemoveProductInTransaction(infoOrder));
  };
  
  const handlerSetProductInTransactions = (infoOrder) => {
    dispatch(setProductInTransactions(infoOrder));
  };
  
  const handlerSetAmountToProductInTransaction = (infoOrder) => {
    dispatch(setAmountToProductInTransaction(infoOrder));
  };

  const handlerUpdateProductForAddToOrder = (infoStock) => {
    dispatch(updateProductForAddToOrder(infoStock));
  };

  const handlerUpdateProductReturnAmountToOrder = (infoStock) => {
    dispatch(updateProductReturnAmountToOrder(infoStock));
  };

  const handlerSetStock = (infoStock) => {
    dispatch(setStock(infoStock));
  };

  const handlerResetStock = () => {
    dispatch(resetStock());
  };

  const handlerSetTableData = (infoTableTransaction) => {
    dispatch(setTableData(infoTableTransaction));
  };

  const getStock = async () => {
    const responseStock = await getRequest('stock', {}, 'stock', false);
    if (responseStock.status === 200) {
      handlerSetStock(responseStock.data.dataResponse);
    }
  };

  useEffect(() => {
    handlerResetStock();
    getStock();
  }, []);
  // #endregion

  // #region PRODUCTS
  const products = useSelector((state) => state.products.value);

  const handlerSetProducts = (infoProducts) => {
    dispatch(setProducts(infoProducts));
  };

  const getProduct = async () => {
    const requestProduct = await getRequest('product', {}, 'product', false);
    handlerSetProducts(requestProduct.data.dataResponse);
  };
  
  useEffect(() => {
    if (products.length === 0) {
      getProduct();
    }
  }, []);
  // #endregion

  // #region CATEGORY
  const categories = useSelector((state) => state.category.value);

  const handlerSetCategory = (infoCategory) => {
    dispatch(setCategory(infoCategory));
  };

  const getCategory = async () => {
    const requestCategory = await getRequest('category', {}, 'category', false);
    handlerSetCategory(requestCategory.data.dataResponse);
  };


  useEffect(() => {
    if (categories.length === 0) {
      getCategory();
    }
  }, []);

  // #endregion

  // #region EMPLOYEES
  const employee = useSelector((state) => state.employees.value);

  const handlerSetEmployees = (infoEmployees) => {
    dispatch(setEmployees(infoEmployees));
  };

  const getEmployees = async () => {
    const requestEmployee = await getRequest('employees', {}, 'employees', false);
    handlerSetEmployees(requestEmployee.data.dataResponse);
  };
  
  useEffect(() => {
    if (employee.length === 0) {
      getEmployees();
    }
  }, []);
  // #endregion

  const getPaymentMethod = async () => {
    const requestPaymentMethod = await getRequest('payment-method', {}, 'payment-method', false);
    setpaymentMethodList(requestPaymentMethod.data.dataResponse);
  };

  useEffect(() => {
    getPaymentMethod();
  }, []);

  const getTables = async () => {
    const requestTable = await getRequest('tables', {}, 'tables', false);
    settables(requestTable.data.dataResponse);
  };

  useEffect(() => {
    getTables();
  }, []);
  // #endregion

  const checkTransaction = async () => {
    
    const responseCheck = await postRequest('transaction-detail', {
      createdate: query.get('transaction'),
    }, 'transaction-detail', false);
    if (responseCheck.status === 200) {
      if (responseCheck.data.dataResponse.length !== 0) {
        const item = responseCheck.data.dataResponse[0];
        setTransaction((prevState) => ({
          ...prevState,
          id: item.id,
          createdate: item.createdate,
          employee: item.employee,
          paymentMethod: item.paymentMethod,
          products: item.products,
          totalAmount: item.totalAmount,
          table: item.table,
          tableData: item.tableData ? item.tableData : {},
          isSplitPayment: item.isSplitPayment ? true : false,
          splitPayment: item.splitPayment ? item.splitPayment : []
        }));
        setOrder(item.products);
        handlerSetTableData(item.tableData);

        // handlerSetOrderInTransactions(item.products);
        setprocessBtn(false);
      }
    }
  };

  useEffect(() => {
    if (query.get('transaction') !== null) {
      checkTransaction();
    }
  }, []);

  // #region EDIT TRANSACTION
  const [typeDiscount, setTypeDiscount] = useState("");
  
  const changeInputEditTotalOrder = (e) => {
    setcommentTransaction("");
    setTypeDiscount(e.target.value);
  };

  // #region Method || set value for edit transaction
  const handlerSetTotalAmountEdit = (e) => {
    if (e !== '') {
      const totalEdit = parseInt(e, 10);
      settotalAmountEdit(totalEdit);
    } else {
      const totalEdit = 0;
      settotalAmountEdit(totalEdit);
    }
  };
  // #endregion

  const returnTotalOrder = () => {
    const totalValueOrder = order.reduce((acc, cur) => {
      if (cur.addValue === true) {
        return acc + (cur.amount * cur.price);
      }
      return acc;
    }, 0);
    return totalValueOrder;
  };

  const [percentDiscount, setPercentDiscount] = useState(0);

  const handlerAddDiscountTotalEdit = (e) => {
    const discountPercent = parseInt(e.target.value, 10);

    const discountAmount = (returnTotalOrder() * discountPercent) / 100;
    const discountedValue = returnTotalOrder() - discountAmount;

    setPercentDiscount(discountPercent);
    settotalAmountEdit(discountedValue);

    setCommentTransactionEdit(`Venta finalizada con un ${discountPercent}% de descuento`);
  };

  // #region Method || Set comment for edit transaction
  const setCommentTransactionEdit = (e) => {
    const totalEdit = e;
    setcommentTransaction(totalEdit);
  };
  // #endregion
  // #endregion

  // #region DOM REGION
  // #region DOM || Scroll horizontal categories
  function nextScroll() {
    document.getElementById('carousel-products').scrollLeft += 150;
  }

  function backScroll() {
    document.getElementById('carousel-products').scrollLeft -= 150;
  }
  // #endregion

  // #region DOM || Filter products for Name input search
  const handlerfiltername = (e) => {
    setFilterProductName(e);
  };
  // #endregion

  // #region DOM || Close alerts
  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };
  // #endregion
  // #endregion

  const handlerSetStockProductToOrder = (productItem, index) => {
    if (productItem.amountRemaining !== 0) {
      if (index === -1) {
        if (transaction.paymentMethod === '' || transaction.paymentMethod === 'Venta asignada a la mesa, no pagada') {
          if (productItem.amountRemaining !== 0) {
            const newItem = {
              id: productItem.id,
              name: productItem.name,
              category: productItem.category,
              categoryName: productItem.categoryName,
              amount: 1,
              price: parseInt(productItem.price, 10),
              fromStock: true,
              addValue: true,
            };
            setOrder([...order, newItem]);

            handlerSetProductInTransactions(newItem);
            handlerUpdateProductForAddToOrder({
              id: productItem.id.replace('duplicate-', ''),
              name: productItem.name,
              category: productItem.category,
              categoryName: productItem.categoryName,
              amount: 1,
              price: parseInt(productItem.price, 10),
              fromStock: true,
              addValue: true,
            });

            if (order.length === 0 && order.length < 0) {
              setprocessBtn(true);
            } else {
              setprocessBtn(false);
            }
          } else {
            setmodalAlertNoEdit(true);
          }
        }
      } else {
        const editItem = order[index];

        if (transaction.paymentMethod === '' || transaction.paymentMethod === 'Venta asignada a la mesa, no pagada') {
          if (productItem.amountRemaining !== 0) {

            if (transactionRedux.products.length === 0) {
              const newItem = {
                id: productItem.id,
                name: productItem.name,
                category: productItem.category,
                categoryName: productItem.categoryName,
                amount: 1,
                price: parseInt(productItem.price, 10),
                fromStock: true,
                addValue: true,
              };

              handlerSetProductInTransactions(newItem);
            } else {
              handlerSetAmountToProductInTransaction({
                id: editItem.id,
              });
            }

            handlerUpdateProductForAddToOrder({
              id: editItem.id.replace('duplicate-', ''),
              amount: 1,
            });

            setOrder(order.map((item, i) => {
              if (index === i) {
                return {...item, amount: (item.amount + 1)};
              }
              return item;
            }));
          }
        } else {
          setmodalAlertNoEdit(true);
        }
      }
    } else {
      setmsmAlert('Este producto no tiene cantidades disponibles.');
      setalertError(true);
    }
  };

  // #region ADD PRODUCT TO ORDER
  // #region PRODUCT FROM STOCK
  // #region Method || Open modal for add product from Stock
  const handlerModalStock = (e) => {
    const productItem = stock.find((x) => x.id === e);

    if (order.find((i) => i.id === e) === undefined) {
      if (productItem.amountRemaining !== 0) {
        if (transaction.paymentMethod === '' || transaction.paymentMethod === 'Venta asignada a la mesa, no pagada') {
          const itemProduct = stock.find((x) => x.id === e);
          setItemOrder(itemProduct);
          setpriceProduct(itemProduct.price);

          const arrRemaining = Array.from(Array(itemProduct.amountRemaining + 1), (_, x) => x);
          arrRemaining.shift();
          setAmountList(arrRemaining);

          showModalStock(true);
        } else {
          setmodalAlertNoEdit(true);
        }
      } else {
        setmsmAlert('Este producto no tiene cantidades disponibles.');
        setalertError(true);
      }
    } else {
      const productItem = stock.find((x) => x.id === e);
      
      const duplicateProduct = {
        active: productItem.active,
        amountRemaining: productItem.amountRemaining,
        amountSell: productItem.amountSell,
        amountStock: productItem.amountStock,
        branchOffice: productItem.branchOffice,
        category: productItem.category,
        categoryName: productItem.categoryName,
        id: `duplicate-${productItem.id}`,
        name: productItem.name,
        photo: productItem.photo,
        price : productItem.price
      };

      const index = order.findIndex((x) => x.id === duplicateProduct.id);
      handlerSetStockProductToOrder(duplicateProduct, index);
    }
  };
  // #endregion

  // #region Method || Add product from Stock to order with right click
  const handlerContextClicStock = (e) => {
    setUpdateStock(true);
    const index = order.findIndex((x) => x.id === e.target.value);
    const productItem = stock.find((x) => x.id === e.target.value);

    handlerSetStockProductToOrder(productItem, index);

    e.preventDefault();
  };
  // #endregion

  // #region Method || Close modal for add product & reset values
  const handlerCloseModalStock = () => {
    setItemOrder({
      id: '', name: '', category: '', categoryName: '', materials: [], photo: '', price: '', discountPercent: 0,
    });
    setpriceProduct(0);
    setAmountList([1]);
    showModalStock(false);
  };
  // #endregion
  // #endregion

  // #region PRODUCT OUT STOCK

  const handlerSetProductToOrder = (productItem, index) => {
    // if (order.find((i) => i.id === e) === undefined) {}
    if (index === -1) {
      if (transaction.paymentMethod === '' || transaction.paymentMethod === 'Venta asignada a la mesa, no pagada') {
        const newItem = {
          id: productItem.id,
          name: productItem.name,
          category: productItem.category,
          categoryName: productItem.categoryName,
          amount: 1,
          price: parseInt(productItem.price, 10),
          fromStock: false,
          addValue: true,
        };
        setOrder([...order, newItem]);
        handlerSetProductInTransactions(newItem);

        if (order.length === 0 && order.length < 0) {
          setprocessBtn(true);
        } else {
          setprocessBtn(false);
        }
      } else {
        setmodalAlertNoEdit(true);
      }
    } else {
      const editItem = order[index];

      if (transaction.paymentMethod === '' || transaction.paymentMethod === 'Venta asignada a la mesa, no pagada') {
        setOrder(order.map((item, i) => {
          if (index === i) {
            return {...item, amount: (item.amount + 1)};
          }
          return item;
        }));
        
        handlerSetAmountToProductInTransaction({
          id: editItem.id,
        });
      } else {
        setmodalAlertNoEdit(true);
      }
    }
  };

  // #region Method || Open modal for add product out Stock
  const handlerModal = (e) => {
    if (order.find((i) => i.id === e) === undefined) {
      if (transaction.paymentMethod === '' || transaction.paymentMethod === 'Venta asignada a la mesa, no pagada') {
        const productItem = products.find((i) => i.id === e);
        setItemOrder(productItem);
        setpriceProduct(productItem.price);
        showModal(true);
      } else {
        setmodalAlertNoEdit(true);
      }
    } else {
      const productItem = products.find((i) => i.id === e);

      const duplicateProduct = {
        branchOffice: productItem.branchOffice,
        category: productItem.category,
        categoryName: productItem.categoryName,
        id: `duplicate-${productItem.id}`,
        materials: productItem.materials,
        name: productItem.name,
        photo: productItem.photo,
        price: productItem.price,
      };

      const index = order.findIndex((x) => x.id === duplicateProduct.id);    
      handlerSetProductToOrder(duplicateProduct, index);
    }
  };
  // #endregion

  // #region Method || Add product out Stock to order with right click
  const handlerContextClick = (e) => {
    const index = order.findIndex((x) => x.id === e.target.value);
    const productItem = products.find((i) => i.id === e.target.value);

    handlerSetProductToOrder(productItem, index);

    e.preventDefault();
  };
  // #endregion
  // #endregion
  // #endregion

  // #region Method || Close modal for add product
  const handlerCloseModal = () => {
    setItemOrder({
      id: '', name: '', category: '', categoryName: '', materials: [], photo: '', price: '', discountPercent: 0,
    });
    setpriceProduct(0);
    setcurrentAmount(1);
    showModal(false);
  };
  // #endregion

  // #region Method || Close modal edit for products in Stock
  const handlerCloseEditModalStock = () => {
    seteditProduct({});
    setpriceProduct(0);
    setcurrentEditAmount(1);
    setAmountList([1]);
    seteditModalStock(false);
  };
  // #endregion

  // #region Method || Close modal edit for products out Stock
  const handlerCloseEditModal = () => {
    seteditProduct({});
    setpriceProduct(0);
    setcurrentEditAmount(1);
    seteditModal(false);
    seteditModalStock(false);
  };
  // #endregion

  // #region EDIT PRODUCT IN 'FACTURA'
  // #region Method || Edit product in order
  const handlerEditToOrder = () => {
    const index = order.findIndex((x) => x.id === editProduct.id);
    const editItem = order[index];

    if (editItem.fromStock === true) {
      if (editItem.id.includes('duplicate-')) {
        const idUpdateItem = editItem.id.replace('duplicate-', '');

        handlerUpdateProductReturnAmountToOrder({
          id: idUpdateItem,
          currentAmount: currentEditAmount,
          prevAmount: editItem.amount,
        });
      } else {
        handlerUpdateProductReturnAmountToOrder({
          id: editItem.id,
          currentAmount: currentEditAmount,
          prevAmount: editItem.amount,
        });        
      }

      setUpdateStock(true);
    }

    setOrder(order.map((item, i) => {
      if (index === i) {
        return {...item, amount: currentEditAmount};
      }
      return item;
    }));

    handlerCloseEditModal();
  };
  // #endregion

  // #region Eliminar producto
  const handlerRemoveToOrder = (item) => {
    if (item.fromStock === true) {

      if (item.id.includes('duplicate-')) {
        const returnAmountToStock = {
          id: item.id.replace('duplicate-', ''),
          currentAmount: 0,
          prevAmount: item.amount,
        };

        handlerUpdateProductReturnAmountToOrder(returnAmountToStock);
      } else {
        handlerUpdateProductReturnAmountToOrder({
          id: item.id,
          currentAmount: 0,
          prevAmount: item.amount,
        });
      }

      setUpdateStock(true);
      handlerSetRemoveProductInTransaction(item);
      setUpdateStockProductDeleteFromOrder((prevItems) => [...prevItems, item]);
    }

    const items = order.filter((prodItem) => prodItem !== item);
    setOrder(items);

    if (order.length === 1 || order.length < 1) {
      setprocessBtn(true);
    } else {
      setprocessBtn(false);
    }
  };
  // #endregion

  // #region Method || Modify amount of producto in modal for add product to order
  const handlerChangeAmount = (e) => {
    const res = parseInt(itemOrder.price, 10) * parseInt(e.target.value, 10);
    setcurrentAmount(e.target.value);
    setpriceProduct(res);
  };
  // #endregion

  // #region Method || Add Product from Stock to Order
  const handlerAddToOrderStock = () => {
    const newItem = {
      id: itemOrder.id,
      name: itemOrder.name,
      category: itemOrder.category,
      categoryName: itemOrder.categoryName,
      amount: currentAmount,
      price: parseInt(itemOrder.price, 10),
      fromStock: true,
      addValue: true,
    };

    setOrder([...order, newItem]);
    handlerSetProductInTransactions(newItem);

    setUpdateStock(true);

    if (order.length === 0 && order.length < 0) {
      setprocessBtn(true);
    } else {
      setprocessBtn(false);
    }

    handlerUpdateProductForAddToOrder(newItem);

    handlerCloseModalStock();
  };
  // #endregion

  // #region Method || Add Product out Stock to order
  const handlerAddToOrder = () => {
    const newItem = {
      id: itemOrder.id,
      name: itemOrder.name,
      category: itemOrder.category,
      categoryName: itemOrder.categoryName,
      amount: currentAmount,
      price: parseInt(itemOrder.price, 10),
      fromStock: false,
      addValue: true,
    };

    setOrder([...order, newItem]);
    handlerSetProductInTransactions(newItem);

    if (order.length === 0 && order.length < 0) {
      setprocessBtn(true);
    } else {
      setprocessBtn(false);
    }

    handlerCloseModal();
  };
  // #endregion

  // #region Method || Open modal for edit amount product
  const handlerEditModal = (item) => () => {
    seteditProduct(item);
    setpriceEditProduct(item.price);
    setcurrentEditAmount(item.amount);
    const res = parseInt(item.price, 10) * parseInt(item.amount, 10);
    setpriceEditProduct(res);

    // If product stay in stock
    if (item.fromStock === true) {
      if (item.id.includes('duplicate-')) {
        const idUpdateItem = item.id.replace('duplicate-', '');

        const productItem = stock.find((x) => x.id === idUpdateItem);
        const arrRemaining = Array.from(
          Array((productItem.amountRemaining + item.amount) + 1), (_, x) => x,
        );
        arrRemaining.shift();
        setAmountList(arrRemaining);
      } else {
        const productItem = stock.find((x) => x.id === item.id);
        const arrRemaining = Array.from(
          Array((productItem.amountRemaining + item.amount) + 1), (_, x) => x,
        );
        arrRemaining.shift();
        setAmountList(arrRemaining);
      }

      seteditModalStock(true);
    // If product stay out stock
    } else {
      seteditModal(true);
    }
  };
  // #endregion

  // #region Method || Change amount of product in order
  const handlerChangeAmountEditProduct = (event) => {
    const res = parseInt(editProduct.price, 10) * parseInt(event.target.value, 10);
    setcurrentEditAmount(event.target.value);
    setpriceEditProduct(res);
  };
  // #endregion

  // #region Method || Show modal for finish order
  const handlerProcess = () => {
    const transactionProcess = transaction;
    if (transactionProcess.id === '') {
      transactionProcess.createdate = moment().format('YYYY-MM-DDTHH:mm:ss.SSS');
    }
    transactionProcess.products = order;
    transactionProcess.totalAmount = order.reduce((acc, cur) => {
      if (cur.addValue === true) {
        return acc + (cur.amount * cur.price);
      }
      return acc;
    }, 0);

    if (order.filter((pr) => pr.addValue === true)) {
      setAnyNoValue(true);
    }

    if (query.get('idMesa')) {
      const nametable = tables.find((x) => x.id === query.get('idMesa'));
      transactionProcess.table = nametable.id;
      transactionProcess.tableName = nametable.name;
    }
    setTransaction(transactionProcess);
    setmodalMetododePago((modal) => !modal);
  };

  const changeEmployee = (id) => {
    const nameEmployee = employee.find((x) => x.id === id);
    const transactionEmployee = transaction;
    transactionEmployee.employee = nameEmployee.id;
    transactionEmployee.employeeName = `${nameEmployee.name} ${nameEmployee.lastName}`;

    const itemComments = newComment;
    itemComments.emailRegister = `${nameEmployee.name} ${nameEmployee.lastName}`;

    if (transactionEmployee.employee !== '') {
      setbtnFinish(false);
    }
    setNewComment(itemComments);
  };
  // #endregion

  // #region Method || Close modal for finish order and reset any values
  const handlerCloseModalPayMethod = () => {
    const item = transaction;
    if (item.isEdit === false) {
      seteditTotalTransaction(false);
      setpaymentMethodView(false);
    }
    setmodalMetododePago((modal) => !modal);
  };
  // #endregion

  // #region Method || Assign init payment method
  const setPaymentMethod = (id) => {
    setTransaction((prevInfo) => ({
      ...prevInfo,
      paymentMethod: id
    }));
  };
  // #endregion

  // #region Method || Split payments
    const [splitPaymentMethod, setSplitPaymentMethod] = useState({
      name: '',
      amount: 0
    });
    const [splitPaymentList, setSplitPaymentList] = useState([]);

    const handlerChangeValuesSplitPaymentMethod = (e) => {
      const { name, value } = e.target;

      if (name === "name" || name === "amount") {
        setSplitPaymentMethod((prevInfo) => ({
          ...prevInfo,
          [name]: value
        }));
      }
    };

    const addSplitPaymentMethod = () => {
      const newItem = {
        id: splitPaymentList.length + 1,
        name: splitPaymentMethod.name,
        amount: splitPaymentMethod.amount
      }

      setSplitPaymentList((prevList) => [...prevList, newItem]);

      setSplitPaymentMethod({
        name: '',
        amount: 0
      });
    };

    const removeSplitPaymentMethod = (id) => {
      setSplitPaymentList((prevList) => prevList.filter((x) => x.id !== id));
    };

    const returnTotalSplitPayment = () => {
      const totalValueOrder = splitPaymentList.reduce((acc, cur) => {
        return parseInt(acc) + parseInt(cur.amount);
      }, 0);
      return totalValueOrder;
    };

    const resetSplitPayments = () => {
      setSplitPaymentMethod({
        name: '',
        amount: 0
      });
      setSplitPaymentList([]);
    };
  // #endregion

  // #region Method || Assign cash as payment method to transaction & show exchange money view
  const handlerexchangeView = () => {
    setexchangeView(true);
    setOnlinePaymentView(false);
    setSplitPaymentView(false);
    resetSplitPayments();

    setPaymentMethod('Efectivo');
  };
  // #endregion

  // #region Method
  // Assign credit card/online payment method to transaction & show online payment view
  const onlinePayment = () => {
    setOnlinePaymentView(true);
    setexchangeView(false);
    setSplitPaymentView(false);
    resetSplitPayments();
  };
  
  const splitPayment = () => {
    setSplitPaymentView(true);
    setOnlinePaymentView(false);
    setexchangeView(false);
  };
  // #endregion

  // #region Group methods || Methods for insert or update transaction

  // #region Method || Update Stock
  const [updateStockProductDeleteFromOrder, setUpdateStockProductDeleteFromOrder] = useState([]);

  const handlerRequestUpdateStock = async (stockToUpdate) => {
    const resTransactionStock = await putRequest('transaction-stock', stockToUpdate, 'transaction-stock', false);
    if (resTransactionStock.status === 200) {
      setmsmAlert('Stock Actualizado');
      setalertSuccess(true);
    } else {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };

  const handlerUpdateStock = async () => {
    if (updateStockProductDeleteFromOrder.length !== 0) {
      const updateStockProductDelete = stock.filter((s) => updateStockProductDeleteFromOrder.find((so) => s.id === so.id));
      await handlerRequestUpdateStock(updateStockProductDelete);
    } else {
      const stockOrder = order.filter((s) => s.fromStock === true);
      const updateStockProductList = stock.filter((s) => stockOrder.find((so) => s.id === so.id));
  
      await handlerRequestUpdateStock(updateStockProductList);
    }
  };
  // #endregion

  // #region Method || Insert transaction
  const hablderTransaction = async () => {
    if (updateStock === true) {
      await handlerUpdateStock();
    }

    console.log(transaction);

    handlerStartLoading();
    const responseCheck = await postRequest('transaction', transaction, 'transaction', false);
    if (responseCheck.status === 200) {
      if (responseCheck.data.dataResponse.status === true) {
        setTransaction({
          id: '',
          createdate: '',
          employee: '',
          employeeName: '',
          paymentMethod: '',
          products: [],
          totalAmount: 0,
          table: 'pedido',
          tableName: 'pedido',
          comments: [],
          isEdit: false,
          stateTransaction: 0,
          tableData: {},
          isSplitPayment: false,
          splitPayment: []
        });
        setOrder([]);
        setmsmAlert(responseCheck.data.dataResponse.message);
        setalertSuccess(true);
        setpaymentMethodView(false);
        setbtnFinish(true);
        handlerResetTransaction();
        if (query.get('idMesa') !== null && responseCheck.data.status === true) {
          history.push('/tables');
        }
        handlerStopLoading();
      } else {
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }
    } else {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };
  // #endregion

  // #region Method || Update order & update stock if is necesary
  const handlerUpdateOrder = () => {
    const transactionItem = transaction;
    transactionItem.stateTransaction = 4;
    transactionItem.products = order;
    transactionItem.totalAmount = order.reduce((acc, cur) => {
      if (cur.addValue === true) {
        return acc + (cur.amount * cur.price);
      }
      return acc;
    }, 0);

    setTransaction(transactionItem);

    hablderTransaction();
  };
  // #endregion

  // #region Method || Launch request for insert or update transaction
  const handlerTransaction = (id) => {
    const itemComments = newComment;

    const transactionItem = transaction;
    transactionItem.stateTransaction = id;

    if (itemComments.comment !== '') {
      transactionItem.comments.push(itemComments);
    }

    if (id === 1 || id === 5 || id === 6) {
      if (anyNoValue === true) {
        for (let itemOrder of order) {
          const commentNoValue = itemOrder;

          if (commentNoValue.addValue === false) {
            const itemComment = {
              emailRegister: transactionItem.employeeName,
              createdate: transactionItem.createdate,
              comment: `${commentNoValue.name} ha sido incluido en el pedido pero no se suma en el valor total de la venta.`,
            };
  
            transactionItem.comments.push(itemComment);
            transactionItem.isEdit = true;
          }
        }
      }
    }

    if (id === 2) {
      transactionItem.paymentMethod = 'Venta asignada a la mesa, no pagada';
    }

    if (id !== 2) {
      if (transactionItem.paymentMethod === '' || transactionItem.paymentMethod === 'Venta asignada a la mesa, no pagada') {
        transactionItem.paymentMethod = 'Efectivo';
      }
    }

    if (id === 2 || id === 3 ) {
      transactionItem.tableData = tableDataTransaction;
    }
    
    if (splitPaymentView) {
      if (splitPaymentList.length > 0) {
        transactionItem.paymentMethod = 'Pago dividido';
        transactionItem.isSplitPayment = splitPaymentView;
        transactionItem.splitPayment = splitPaymentList;
      }
    }

    setTransaction(transactionItem);

    // Crear
    if (transactionItem.id === '') {
      transactionItem.id = uuidv4();
      setTransaction(transactionItem);

      hablderTransaction();
    }
    // Update
    if (transactionItem.id !== '') {
      hablderTransaction();
    }

    setprocessBtn(true);
    setmodalMetododePago((modal) => !modal);
  };
  // #endregion
  // #endregion

  // #region Method || Finish transaction
  const handlerFinishPaymentMethod = () => {
    const item = transaction;

    if (item.id === '') {
      item.paymentMethod = 'Efectivo';
      item.id = uuidv4();
      setTransaction(item);
    }

    setpaymentMethodView((modal) => !modal);
  };
  // #endregion

  // #region Method || Finish transaction with value modify
  const handlerFinishPaymentMethodEditTransaction = () => {
    const newComments = newComment;
    newComments.comment = commentTransaction;
    newComments.createdate = moment().format('YYYY-MM-DDTHH:mm:ss.SSS');
    setNewComment(newComments);

    const item = transaction;
    item.totalAmount = totalAmountEdit;
    item.isEdit = true;

    if (item.id === '') {
      item.paymentMethod = 'Efectivo';
      item.id = uuidv4();
    }

    setTransaction(item);
    setpaymentMethodView((modal) => !modal);
  };
  // #endregion

  // #region Method || Reset order
  const handlerResetorder = () => {
    const filterStock = order.filter((x) => x.fromStock === true);

    if (filterStock.length >= 1) {
      for (let itemFilter of filterStock) {
        handlerUpdateProductReturnAmountToOrder({
          id: itemFilter.id,
          currentAmount: 0,
          prevAmount: itemFilter.amount,
        });
      }
    }

    setOrder([]);
    setUpdateStock(true);

    if (order.length === 1 || order.length < 1) {
      setprocessBtn(true);
    } else {
      setprocessBtn(false);
    }
  };
  // #endregion

  // #region Method || Exchange money for client
  const handlerExchangeMoney = (e) => {
    const transactionItem = transaction;

    setexchangeMoney(e);
    if (e > transactionItem.totalAmount) {
      const res = e - transactionItem.totalAmount;
      setchangeMoneyClient(res);
    } else {
      setchangeMoneyClient(0);
    }
  };

  const handlerCloseNoEditModal = () => {
    setmodalAlertNoEdit(false);
  };
  // #endregion

  // #region Get data company
  const [company, setCompany] = useState({
    company: '',
    companyName: '',
    branchOffice: '',
    branchOfficeName: '',
    address: '',
    phoneNumber: '',
    nit: '',
  });

  const getDataCompany = () => {
    const dataCompany = getCompany();

    setCompany((prevState) => ({
      ...prevState,
      company: dataCompany.company,
      companyName: dataCompany.companyName,
      branchOffice: dataCompany.branchOffice,
      branchOfficeName: dataCompany.branchOfficeName,
      address: dataCompany.address,
      phoneNumber: dataCompany.phoneNumber,
      nit: dataCompany.nit,
    }));
  };

  useEffect(() => {
    getDataCompany();
  }, []);
  // #endregion

  // #endregion

  const handlerAddCommentAnyNoValue = () => {
    const checkOrder = order;
    if (checkOrder.filter((x) => x.addValue === false).length >= 1) {
      setAnyNoValue(true);
    } else {
      setAnyNoValue(false);
    }
  };

  const handlerisValue = (item) => {
    const index = order.findIndex((x) => x.id === item.id);

    setOrder(order.map((item, i) => {
      if (index === i) {
        return {...item, addValue: !item.addValue};
      }
      return item;
    }));

    handlerAddCommentAnyNoValue();
  };

  const componentForSelectEmployee = () => {
    return (
      <div className="col-12 mb-3 text-center">
        <FormControl variant="standard" className="w-100">
          <InputLabel id="label-select-employee">
            Empleado quien registra
          </InputLabel>
          <Select
            labelId="select-update-category"
            id="select-update-category"
            defaultValue=""
            onChange={(e) => changeEmployee(e.target.value)}
            MenuProps={MenuProps}
          >
            {employee.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {`${item.name} ${item.lastName}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  return (
    <div className="w-100 mt-4 p-3 btn-rounded main-container">
      <div className="d-flex h-100">
        <div className="product-list py-2 pe-2">
          <div className="w-100">
            {
              query.get('idMesa') ? (
                tables.filter((x) => x.id === query.get('idMesa')).map((x) => (
                  <h3 className="text-white" key={x.id}>
                    {x.name}
                    &nbsp;
                    {tableDataTransaction.id && (
                      <small style={{ fontSize: '16px', color: '#01b6d8', float: 'right' }}>
                        {tableDataTransaction.allChairs === true ? (
                          <>Toda la mesa</>
                        ) : (
                          <>
                            Silla&#40;s&#41;&nbsp;
                            {tableDataTransaction.chairSelected}
                            {tableDataTransaction.chairlist.map((chairId, index) => (
                              <>
                                {index !== tableDataTransaction.chairlist.length && ', '}
                                {chairId}
                              </>
                            ))}
                          </>
                        )}
                      </small>
                    )}
                  </h3>
                ))
              ) : (
                <h3 className="text-white">
                  Pedido
                </h3>
              )
            }
          </div>
          <div className="row mx-0">
            <div className="col-sm-1 col-md-1 col-lg-1 z-index-9 px-0 ps-2">
              <button id="btn-c-back" className="my-1 btn btn-back-item" type="button" onClick={backScroll}>
                <ArrowBackIosNewIcon style={{ color: 'white' }} />
              </button>
            </div>
            <div className="col-sm-10 col-md-10 col-lg-10 px-0">
              <div id="carousel-products" className="carousel-horozontal">
                <button
                  key="0"
                  id="0"
                  onClick={() => setCategoryFilter('0')}
                  type="button"
                  className={`${categoryFilter === '0' ? 'btn-filter-product-active' : ''} mx-1 my-1 btn btn-filter-product text-white rounded-pill`}
                >
                  Todos
                </button>
                {categories.map((item) => (
                  <button
                    key={item.id}
                    id={item.id}
                    onClick={() => setCategoryFilter(item.id)}
                    type="button"
                    className={`${categoryFilter === item.id ? 'btn-filter-product-active' : ''} mx-1 my-1 btn btn-filter-product text-white rounded-pill`}
                  >
                    {item.name}
                  </button>
                ))}
              </div>
            </div>
            <div className="col-sm-1 col-md-1 col-lg-1 z-index-9 px-0 pe-2">
              <button id="btn-c-next" className="my-1 btn btn-next-item float-end" type="button" onClick={nextScroll}>
                <ArrowForwardIosIcon style={{ color: 'white' }} />
              </button>
            </div>
          </div>
          <div className="w-100 mt-2 pe-3">
            <CssTextField
              variant="standard"
              label="Nombre del Producto"
              className="w-100 mb-2"
              InputProps={{
                style: { color: 'white' },
                autoComplete: 'off',
              }}
              onChange={(e) => handlerfiltername(e.target.value)}
            />
          </div>
          <div id="product-stock" className="row mx-0 pt-1 inside-scroll-container height-product-list flex-start">
            <p className="text-white h6">
              Productos de stock
            </p>
            {stock.length === 0 ? (
              <div className="wrapper-without-stock-list">
                <div className="mb-3">
                  <button
                    id="x"
                    type="button"
                    value=""
                    className="card card-body bg-glass border-0 w-100 card-product rounded cursor-pointer text-white"
                  >
                    Sin registro de stock.
                  </button>
                </div>
              </div>
            ) : (
              stock.length !== 0 && (
                <div className="wrapper-product-list">
                  {stock.filter((s) => (filterProductName === '' ? s : s.name.toLowerCase().includes(filterProductName.toLowerCase()))).map((item) => (
                    item.amountRemaining !== 0 && (
                      <div
                        key={item.id}
                        className={`${categoryFilter === '0' ? '' : categoryFilter === item.category ? '' : 'd-none'} m-2`}
                      >
                        <button
                          id={item.id}
                          onClick={() => handlerModalStock(item.id)}
                          onContextMenu={(e) => handlerContextClicStock(e)}
                          type="button"
                          value={item.id}
                          className={`${order.filter((p) => p.id === item.id).length !== 0 ? 'card-product-in-order' : 'card-product'} card card-body bg-glass border-0 w-100 rounded cursor-pointer text-white`}
                        >
                          {item.name}
                          <br />
                          {
                            numberToMoney(item.price)
                          }
                          <br />
                          Cant:&nbsp;
                          {item.amountRemaining}
                        </button>
                      </div>
                    )
                  ))}
                </div>
              )
            )}
            <p className="text-white h6">
              Listado de productos
            </p>
            <div className="wrapper-product-list">
              {products
                .filter((p) => (filterProductName === '' ? p : p.name.toLowerCase().includes(filterProductName.toLowerCase())))
                .filter((p) => !categories
                  .filter((c) => c.enableStock === true).find((cat) => cat.id === p.category))
                .map((item) => (
                  <div
                    key={item.id}
                    className={`${categoryFilter === '0' ? '' : categoryFilter === item.category ? '' : 'd-none'} m-2`}
                  >
                    <button
                      id={item.id}
                      onClick={() => handlerModal(item.id)}
                      onContextMenu={(e) => handlerContextClick(e)}
                      type="button"
                      value={item.id}
                      className={`${order.filter((p) => p.id === item.id).length !== 0 ? 'card-product-in-order' : 'card-product'} card card-body bg-glass border-0 w-100 rounded cursor-pointer text-white`}
                    >
                      {item.name}
                      <br />
                      {
                        numberToMoney(item.price)
                      }
                    </button>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="view-transaction  p-2 bg-glass-light rounded">
          <h3 className="text-dark">
            Factura
          </h3>
          <div className="product-list-factura-container col-sm-12 col-md-12 col-lg-12">
            <table id="table-product-list" className="table table-borderless">
              <thead className="border-bottom-table">
                <tr>
                  <th scope="col" className="col-delete-product">#</th>
                  <th scope="col" className="col-product-product-list">Producto</th>
                  <th scope="col" className="col-amount-product-list">Cantidad</th>
                  <th scope="col" className="col-price-product-list">Precio</th>
                </tr>
              </thead>
              <tbody id="product-list-order" className="">
                {
                  order.map((item) => (
                    <tr key={item.id} id={item.id}>
                      {
                        transaction.paymentMethod === '' || transaction.paymentMethod === 'Venta asignada a la mesa, no pagada' ? (
                          <td className="d-flex justify-content-around">
                            <button
                              className="btn btn-outline-danger rounded-pill"
                              type="button"
                              onClick={() => handlerRemoveToOrder(item)}
                            >                              
                              <DeleteIcon />
                            </button>
                            
                            <button
                              className="btn btn-outline-primary rounded-pill"
                              type="button"
                              onClick={() => handlerEditModal(item)}
                            >
                              <EditIcon />
                            </button>

                            <button
                              className={`btn ${item.addValue === true ? 'btn-outline-danger' : 'btn-outline-success'} rounded-pill`}
                              type="button"
                              onClick={() => handlerisValue(item)}
                            >
                              {item.addValue === true && (
                                <MoneyOffIcon />
                              )}
                              {item.addValue === false && (
                                <AttachMoneyIcon />
                              )}
                            </button>

                          </td>
                        ) : (
                          <td className="d-flex justify-content-around">
                            &nbsp;
                          </td>
                        )
                      }
                      <td>{item.name}</td>
                      <td>{item.amount}</td>
                      <td>
                        {numberToMoney(item.price)}
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className="mb-2 buttons-factura-container px-0 col-sm-12 col-md-12 col-lg-12">
            <div className="row mx-0 w-100">
              <div className="col-sm-12 col-md-12 col-lg-12 col-lx-12">
                <table className="table table-borderless">
                  <tfoot className="border-top-table">
                    <tr>
                      <th scope="col" colSpan="2" className="col-label-total-product-list">Total</th>
                      <th scope="col" id="totalOrder" className="col-total-price-product-list">
                        {numberToMoney(returnTotalOrder())}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
              {
                query.get('transaction') === null
                  ? (
                    <div className="row px-0 mx-0 w-100">
                      <div className="col-sm-6 col-md-6 col-lg-6 col-lx-6">
                        <button className="w-100 btn btn-danger rounded" type="button" onClick={handlerResetorder}>Eliminar Productos</button>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-lx-6">
                        <button className="w-100 btn btn-primary rounded" type="button" onClick={handlerProcess} disabled={processBtn}>Procesar</button>
                      </div>
                    </div>
                  ) : (
                    <div className="row px-0 mx-0 w-100">
                      {transaction.paymentMethod === 'Venta asignada a la mesa, no pagada' ? (
                        <div className="col-12">
                          <div className="row">
                            <div className="col-6">
                              <button className="w-100 btn btn-warning text-white rounded" type="button" onClick={handlerUpdateOrder} disabled={processBtn}>Actualizar Orden</button>
                            </div>
                            <div className="col-6">
                              <button className="w-100 btn btn-primary rounded" type="button" onClick={handlerProcess} disabled={processBtn}>Procesar</button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-12">
                          <button className="w-100 btn btn-primary rounded" type="button" onClick={handlerProcess} disabled={processBtn}>Procesar</button>
                        </div>
                      )}
                    </div>
                  )
                }
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>

      <Dialog
        open={openModalStock}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalStock(); } }}
        transitionDuration={{ enter: 100, exit: 0 }}
      >
        <DialogTitle>
          {itemOrder.name}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-3">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="label-select-update-category">Cantidad</InputLabel>
                <Select
                  labelId="select-update-category"
                  id="select-update-category"
                  value={currentAmount}
                  onChange={(e) => handlerChangeAmount(e)}
                  MenuProps={MenuProps}
                >
                  {amountList.map((item) => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 mb-3">
              <h5>
                Precio{' '}
                <span id="price-product" className="float-end">
                  {numberToMoney(priceProduct)}
                </span>
              </h5>
            </div>
            <div className="col-12 mb-2">
              <div className="row">
                <div className="col-6">
                  <button onClick={handlerCloseModalStock} type="button" className="btn btn-danger w-100">
                    Cancelar
                  </button>
                </div>
                <div className="col-6">
                  <button className="btn btn-primary w-100 h-100" id="btn-addProduct" type="button" onClick={handlerAddToOrderStock}>
                    Agregar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={editModalStock}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseEditModalStock(); } }}
        transitionDuration={{ enter: 100, exit: 0 }}
      >
        <DialogTitle>
          {editProduct.name}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-3">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="label-select-update-category">Cantidad</InputLabel>
                <Select
                  labelId="select-update-amount"
                  id="select-update-amount"
                  value={currentEditAmount}
                  onChange={(e) => handlerChangeAmountEditProduct(e)}
                  MenuProps={MenuProps}
                >
                  {amountList.map((item) => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 mb-3">
              <h6>
                Precio{' '}
                <span id="price-product" className="float-end">
                  {
                    numberToMoney(priceEditProduct)
                  }
                </span>
              </h6>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-lx-6">
                  <button
                    onClick={handlerCloseEditModalStock}
                    type="button"
                    className="btn btn-danger w-100"
                  >
                    Cancelar
                  </button>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-lx-6">
                  <button
                    id="btn-addProduct"
                    type="button"
                    onClick={handlerEditToOrder}
                    className="btn btn-primary w-100 h-100"
                  >
                    Editar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModal(); } }}
        transitionDuration={{ enter: 100, exit: 0 }}
      >
        <DialogTitle>
          {itemOrder.name}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-3">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="label-select-update-category">Cantidad</InputLabel>
                <Select
                  labelId="select-update-category"
                  id="select-update-category"
                  value={currentAmount}
                  onChange={(e) => handlerChangeAmount(e)}
                  MenuProps={MenuProps}
                >
                  {arr.map((item) => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 mb-3">
              <h5>
                Precio{' '}
                <span id="price-product" className="float-end">
                  {numberToMoney(priceProduct)}
                </span>
              </h5>
            </div>
            <div className="col-12 mb-2">
              <div className="row">
                <div className="col-6">
                  <button onClick={handlerCloseModal} type="button" className="btn btn-danger w-100">
                    Cancelar
                  </button>
                </div>
                <div className="col-6">
                  <button className="btn btn-primary w-100 h-100" id="btn-addProduct" type="button" onClick={handlerAddToOrder}>
                    Agregar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={editModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseEditModal(); } }}
        transitionDuration={{ enter: 100, exit: 0 }}
      >
        <DialogTitle>
          {editProduct.name}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-3">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="label-select-update-category">Cantidad</InputLabel>
                <Select
                  labelId="select-update-amount"
                  id="select-update-amount"
                  value={currentEditAmount}
                  onChange={(e) => handlerChangeAmountEditProduct(e)}
                  MenuProps={MenuProps}
                >
                  {arr.map((item) => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 mb-3">
              <h6>
                Precio{' '}
                <span id="price-product" className="float-end">
                  {numberToMoney(priceEditProduct)}
                </span>
              </h6>
            </div>
            <div className="col-12 mb-3">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-lx-6">
                  <button
                    onClick={handlerCloseEditModal}
                    type="button"
                    className="btn btn-danger w-100"
                  >
                    Cancelar
                  </button>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-lx-6">
                  <button
                    id="btn-addProduct"
                    type="button"
                    onClick={handlerEditToOrder}
                    className="btn btn-primary w-100 h-100"
                  >
                    Editar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalMetododePago}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalPayMethod(); } }}
      >
        <DialogContent>
          <div className="d-none">
            <BillToPrint ref={componentToPrint} company={company} transaction={transaction} />
          </div>
          <div>
            {paymentMethodView ? (
              <div className="row mb-2">
                <span className="h4">
                  Método de Pago
                </span>
                <div className="col-4 text-center">
                  <button
                    className={`card card-body w-100 btn-rounded ${exchangeView === true ? ' nav-bar-active border-0 text-white shadow' : ''}`}
                    type="button"
                    onClick={handlerexchangeView}
                  >
                    <h6 className="m-auto">
                      <LocalAtmIcon fontSize="large" />
                      &nbsp;
                      Efectivo
                    </h6>
                  </button>
                </div>
                <div className="col-4 mb-3">
                  <button
                    className={`card card-body w-100 btn-rounded ${onlinePaymentView === true ? ' nav-bar-active border-0 text-white shadow' : ''}`}
                    type="button"
                    onClick={onlinePayment}
                  >
                    <h6 className="m-auto">
                      <CreditCardIcon fontSize="large" />
                      &nbsp;
                      Tarjeta de Crédito
                    </h6>
                  </button>
                </div>
                <div className="col-4 mb-3">
                  <button
                    className={`card card-body w-100 btn-rounded ${splitPaymentView === true ? ' nav-bar-active border-0 text-white shadow' : ''}`}
                    type="button"
                    onClick={splitPayment}
                  >
                    <h6 className="m-auto">
                      <LocalAtmIcon fontSize="large" />{' '}
                      <CreditCardIcon fontSize="large" />
                      &nbsp;
                      Dividir pagos
                    </h6>
                  </button>
                </div>
                {exchangeView && (
                  <>
                    <div className="col-12 mb-3">
                      <h5>Calcular Cambio</h5>
                      <div className="row">
                        <div className="col-6">
                          <TextField
                            variant="standard"
                            label="Dinero Recibido"
                            className="w-100"
                            type="number"
                            error={exchangeMoney === '' || exchangeMoney === 0 || exchangeMoney < returnTotalOrder()}
                            helperText={
                              exchangeMoney === '' ? 'Ingrese un valor Valido!' : 
                              exchangeMoney < returnTotalOrder() && 'Este Valor no puede ser menor al Valor total del Pedido'
                            }
                            onChange={(e) => handlerExchangeMoney(e.target.value)}
                          />
                        </div>
                        <div className="col-4">
                          <TextField
                            variant="standard"
                            label="Total del Pedido"
                            className="w-100"
                            defaultValue={numberToMoney(totalAmountEdit === 0 ? returnTotalOrder() : totalAmountEdit)}
                            disabled
                          />
                        </div>
                        <div className="col-2">
                          <small className="text-muted">
                            Cambio
                          </small>
                          <br />
                          <h5>
                            {numberToMoney(exchangeMoneyClient)}
                          </h5>
                        </div>

                      </div>
                    </div>
                    {exchangeMoney !== 0 && (exchangeMoney > returnTotalOrder() || exchangeMoney > totalAmountEdit) && (
                      componentForSelectEmployee()
                    )}
                  </>
                )}
                {onlinePaymentView && (
                  <div className="row mb-2">
                    <div className="col-12 mb-3">
                      <h5>
                        Forma de pago en linea
                      </h5>
                    </div>
                    {paymentMethodList.map((item) => (
                      <div key={item.id} className="col-3 mb-1 text-center">
                        <label htmlFor={item.id} className="credit-card p-3 shadow-sm cursor-pointer">
                          <div className="row">
                            <div className="col-12 mb-1">
                              <span className="float-start h5">
                                <CreditCardIcon className="text-white me-2" />
                                {item.name}
                              </span>
                              <input
                                id={item.id}
                                className="float-end form-check-input"
                                name="creditcard-payment"
                                type="radio"
                                value={item.name}
                                onClick={() => setPaymentMethod(item.name)}
                              />
                            </div>
                            <div className="col-12">
                              <ViewModuleIcon className="float-start text-warning" />
                            </div>
                            <div className="col-12">
                              <span className="float-end">
                                {item.numberAccount}
                              </span>
                            </div>
                          </div>
                        </label>
                      </div>
                    ))}
                    
                    {transaction.paymentMethod !== "Efectivo" && (
                      componentForSelectEmployee()
                    )}
                  </div>
                )}
                {splitPaymentView && (
                  <>
                    <div className="col-12 mb-3">
                      <h5>
                        Dividir pagos
                      </h5>
                    </div>
                    <div className="col-12 mb-2">
                      <div className="row mb-0">
                        <div className="col-4"><small><b>Forma</b></small></div>
                        <div className="col-4"><small><b>Cuanto paga</b></small></div>
                        <div className="col-4">{''}</div>
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      {splitPaymentList.map((item) => (
                        <div className="row mb-1" key={item.amount}>
                          <div className="col-4">{item.name}</div>
                          <div className="col-4">{numberToMoney(item.amount)}</div>
                          <div className="col-4">
                            <button
                              className="w-100 btn btn-danger btn-sm rounded"
                              type="button"
                              onClick={() => removeSplitPaymentMethod(item.id)}
                            >
                              Eliminar
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-12 mb-2">
                      <div className="row">
                        <div className="col-4">
                          <FormControl variant="standard" className="w-100">
                            <InputLabel id="label-select-employee">
                              Metodo de pago
                            </InputLabel>
                            <Select
                              labelId="select-update-category"
                              id="select-update-category"
                              defaultValue=""
                              name="name"
                              onChange={(e) => handlerChangeValuesSplitPaymentMethod(e)}
                              value={splitPaymentMethod.name}
                              MenuProps={MenuProps}
                            >
                              <MenuItem value="Efectivo">Efectivo</MenuItem>
                              {paymentMethodList.map((item) => (
                                <MenuItem key={item.id} value={item.name}>
                                  {`${item.name}`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-4">
                          <TextField
                            variant="standard"
                            error={splitPaymentMethod.amount === "" || !isNumeric(splitPaymentMethod.amount)}
                            label="Cuanto paga *"
                            className="w-100"
                            name="amount"
                            value={splitPaymentMethod.amount}
                            onChange={(e) => {
                              if (isNumeric(e.target.value) || e.target.value === "") {
                                handlerChangeValuesSplitPaymentMethod(e)
                              }
                            }}
                          />
                        </div>
                        <div className="col-4 d-flex align-items-center">
                          <button
                            className="w-100 btn btn-primary btn-sm rounded"
                            type="button"
                            disabled={
                              splitPaymentMethod.name === "" || // Nombre no puede ser vacío
                              isNaN(splitPaymentMethod.amount) ||      // Amount debe ser un número
                              splitPaymentMethod.amount === "" || // Amount no puede ser vacío
                              parseFloat(splitPaymentMethod.amount) === 0 // Amount no puede ser 0
                            }
                            onClick={() => addSplitPaymentMethod()}
                          >
                            Agregar forma de pago
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <div className="row">
                        <div className="col-8">Total restante</div>
                        <div className="col-4">
                          {totalAmountEdit === 0 ? (
                            numberToMoney(returnTotalOrder() - returnTotalSplitPayment())
                            ) : (
                            numberToMoney(totalAmountEdit - returnTotalSplitPayment())
                          )}
                        </div>
                        {returnTotalSplitPayment() > returnTotalOrder() && (
                          <div className='col-12 mb-2'>
                            <span className="text-danger">El valor es superior al valor de la venta.</span>
                          </div>
                        )}
                      </div>
                    </div>
                    {totalAmountEdit === 0 ? (
                      <>
                        {(returnTotalOrder() - returnTotalSplitPayment()) === 0 && (
                          componentForSelectEmployee()
                        )}
                      </>
                    ) : (
                      <>
                        {(totalAmountEdit - returnTotalSplitPayment()) === 0 && (
                          componentForSelectEmployee()
                        )}
                      </>
                    )}
                  </>
                )}

                {transaction.products.filter((x) => x.addValue === false).length !== 0 && (
                  <div className="mb-2 col-sm-12 col-md-12 col-lg-12">
                    <div className="alert alert-info">
                      Los siguientes productos no se contabilizará en el total de la venta:
                      <ul className="mb-0">
                        {transaction.products.filter((x) => x.addValue === false).map((itemProd) => (
                          <li key={itemProd.id}>
                            {itemProd.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}

                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
                  <button
                    className="btn btn-danger w-100"
                    type="button"
                    onClick={() => handlerCloseModalPayMethod()}
                  >
                    Cancelar
                  </button>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
                  <button
                    className="btn btn-primary w-100"
                    onClick={handlePrint}
                    disabled={btnFinish}
                    type="button"
                  >
                    Imprimir factura
                  </button>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
                  {query.get('idMesa') === null ? (
                    <button
                      className="w-100 btn btn-primary rounded"
                      type="button"
                      onClick={() => handlerTransaction(1)}
                      disabled={btnFinish}
                    >
                      Confirmar
                    </button>
                  ) : (
                    <div>
                      {
                        query.get('transaction') === null
                          ? (
                            <button
                              className="w-100 btn btn-primary rounded"
                              type="button"
                              onClick={() => handlerTransaction(3)}
                              disabled={btnFinish}
                            >
                              Confirmar
                            </button>
                          ) : (
                            <button
                              className="w-100 btn btn-primary rounded"
                              type="button"
                              onClick={() => handlerTransaction(5)}
                              disabled={btnFinish}
                            >
                              Confirmar
                            </button>
                          )
                        }
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 mb-2 align-content-end">
                  {query.get('idMesa') ? (
                    tables.filter((x) => x.id === query.get('idMesa')).map((x) => (
                      <span className="h4" key={x.id}>
                        Pedido a la Mesa{' '}
                        <span className="text-muted">
                          {x.name}
                        </span>
                      </span>
                    ))
                  ) : (
                    <span className="h4">
                      Resumen del Pedido
                    </span>
                  )}
                </div>
                {transaction.paymentMethod === 'Venta asignada a la mesa, no pagada' && (
                  <div className="col-12">
                    <div className="alert alert-info">
                      <b>¡Importante!</b>
                      <br />
                      Sí vas a finalizar la venta, asegurate que los productos
                      que asignaste correspondan a la venta que el cliente desea.
                    </div>
                  </div>
                )}
                <div className="col-12 mb-2 ps-5 pe-5">
                  <table id="table-product-list" className="table table-borderless">
                    <thead className="border-bottom-table">
                      <tr>
                        <th scope="col" className="col-product-product-list">Producto</th>
                        <th scope="col" className="col-amount-product-list">Cantidad</th>
                        <th scope="col" className="col-price-product-list">Precio</th>
                      </tr>
                    </thead>
                    <tbody id="product-list-order">
                      {order.map((item) => (
                        <tr key={item.id} id={item.id}>
                          <td>{item.name}</td>
                          <td>{item.amount}</td>
                          <td>
                            {numberToMoney(item.price)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <table className="table table-borderless">
                    <tfoot className="border-top-table">
                      <tr>
                        <th scope="col" className="col-product-product-list">Total</th>
                        <th scope="col" className="col-amount-product-list">&nbsp;</th>
                        <th scope="col" className="col-total-price-product-list">
                          {numberToMoney(returnTotalOrder())}
                          <span className="float-end">
                            <IconButton
                              className="p-0"
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              onClick={() => seteditTotalTransaction((total) => !total)}
                            >
                              <EditIcon />
                            </IconButton>
                          </span>
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                
                {transaction.products.filter((x) => x.addValue === false).length !== 0 && (
                  <div className="mb-2 col-sm-12 col-md-12 col-lg-12">
                    <div className="alert alert-info">
                      Los siguientes productos no se contabilizará en el total de la venta:
                      <ul className="mb-0">
                        {transaction.products.filter((x) => x.addValue === false).map((itemProd) => (
                          <li key={itemProd.id}>
                            {itemProd.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}

                {editTotalTransaction && (
                  <div className="col-12 mb-2">
                    <div className="row px-5">
                      <div className="col-12 mt-2">
                        <strong>
                          Editar valor total
                        </strong>
                      </div>
                      <div className="col-12 mb-2">
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={typeDiscount}
                            onChange={(e) => changeInputEditTotalOrder(e)}
                          >
                            <FormControlLabel value="input" control={<Radio />} label="Editar valor" />
                            <FormControlLabel value="select" control={<Radio />} label="Agregar descuento" />
                          </RadioGroup>
                        </FormControl>
                      </div>

                      {typeDiscount === "input" && (
                        <div className="col-12 mb-2">
                          <TextField
                            variant="standard"
                            id="price"
                            label="Precio *"
                            className="w-100"
                            InputProps={{ inputProps: { min: 0 } }}
                            defaultValue={returnTotalOrder()}
                            type="number"
                            onChange={(e) => handlerSetTotalAmountEdit(e.target.value)}
                          />
                        </div>
                      )}
                      
                      {typeDiscount === "select" && (
                        <div className="col-12 mb-2">
                          <div className="row">
                            <div className="col-6 mb-2">
                              <FormControl variant="standard" className="w-100">
                                <InputLabel id="label-select-discount">Descuento</InputLabel>
                                <Select
                                  labelId="select-update-discount"
                                  id="select-update-discount"
                                  defaultValue=""
                                  onChange={(e) => handlerAddDiscountTotalEdit(e)}
                                  MenuProps={MenuProps}
                                >
                                  <MenuItem value="5">5%</MenuItem>
                                  <MenuItem value="10">10%</MenuItem>
                                  <MenuItem value="15">15%</MenuItem>
                                  <MenuItem value="20">20%</MenuItem>
                                  <MenuItem value="25">25%</MenuItem>
                                  <MenuItem value="30">30%</MenuItem>
                                  <MenuItem value="35">35%</MenuItem>
                                  <MenuItem value="40">40%</MenuItem>
                                  <MenuItem value="45">45%</MenuItem>
                                  <MenuItem value="50">50%</MenuItem>
                                  <MenuItem value="55">55%</MenuItem>
                                  <MenuItem value="60">60%</MenuItem>
                                  <MenuItem value="65">65%</MenuItem>
                                  <MenuItem value="70">70%</MenuItem>
                                  <MenuItem value="75">75%</MenuItem>
                                  <MenuItem value="80">80%</MenuItem>
                                  <MenuItem value="85">85%</MenuItem>
                                  <MenuItem value="90">90%</MenuItem>
                                  <MenuItem value="95">95%</MenuItem>
                                  <MenuItem value="100">100%</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                            <div className="col-6 mb-2">
                              <TextField
                                variant="standard"
                                id="price"
                                label={`Precio con descuento de ${percentDiscount}%`}
                                className="w-100"
                                value={totalAmountEdit === 0 ? returnTotalOrder() : totalAmountEdit}
                                type="number"
                                disabled="true"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="col-12 mb-2">
                        <TextField
                          variant="standard"
                          label="Comentarios *"
                          id="reason-edit-transaction"
                          type="text"
                          className="w-100"
                          onChange={(e) => setCommentTransactionEdit(e.target.value)}
                          disabled={typeDiscount === "select"}
                          value={commentTransaction}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-12">
                  {query.get('idMesa') === null ? (
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                        <button
                          className="btn btn-danger w-100"
                          type="button"
                          onClick={() => handlerCloseModalPayMethod()}

                        >
                          Cancelar
                        </button>
                      </div>
                      {editTotalTransaction ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                          <button
                            className="btn btn-primary w-100 h-100"
                            type="button"
                            onClick={() => handlerFinishPaymentMethodEditTransaction()}
                            disabled={commentTransaction === ''}
                          >
                            Finalizar venta con descuento
                          </button>
                        </div>
                      ) : (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                          <button
                            className="btn btn-primary w-100 h-100"
                            type="button"
                            onClick={() => handlerFinishPaymentMethod()}
                            disabled={editTotalTransaction}
                          >
                            Finalizar Venta
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {
                        query.get('transaction') === null
                          ? (
                            <div className="row">
                              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
                                <button
                                  className="btn btn-danger w-100 h-100"
                                  type="button"
                                  onClick={() => handlerCloseModalPayMethod()}
                                >
                                  Cancelar
                                </button>
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
                                <button
                                  className="btn btn-warning w-100 h-100 text-white"
                                  type="button"
                                  onClick={() => handlerTransaction(2)}
                                >
                                  Asignar a la Mesa
                                </button>
                              </div>
                              {editTotalTransaction ? (
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
                                  <button
                                    className="btn btn-primary w-100 h-100"
                                    type="button"
                                    onClick={() => handlerFinishPaymentMethodEditTransaction()}
                                    disabled={commentTransaction === ''}
                                  >
                                    Pagar y Asignar a la Mesa con Valor Modificado
                                  </button>
                                </div>
                              ) : (
                                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
                                  <button
                                    className="btn btn-primary w-100 h-100"
                                    type="button"
                                    onClick={() => handlerFinishPaymentMethod()}
                                    disabled={editTotalTransaction}
                                  >
                                    Pagar y Asignar a la Mesa
                                  </button>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              {transaction.paymentMethod !== 'Venta asignada a la mesa, no pagada'
                                ? (
                                  <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                                      <button
                                        className="btn btn-danger w-100"
                                        type="button"
                                        onClick={() => handlerCloseModalPayMethod()}
                                      >
                                        Cancelar
                                      </button>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                                      <button
                                        className="btn btn-primary w-100 h-100"
                                        type="button"
                                        onClick={() => handlerTransaction(6)}
                                      >
                                        Liberar Mesa
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                                      <button
                                        className="btn btn-danger w-100"
                                        type="button"
                                        onClick={() => handlerCloseModalPayMethod()}
                                      >
                                        Cancelar
                                      </button>
                                    </div>
                                    {editTotalTransaction ? (
                                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                                        <button
                                          className="btn btn-primary w-100 h-100"
                                          type="button"
                                          onClick={
                                            () => handlerFinishPaymentMethodEditTransaction()
                                          }
                                          disabled={commentTransaction === ''}
                                        >
                                          Pagar con Valor Modificado
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                                        <button
                                          className="btn btn-primary w-100 h-100"
                                          type="button"
                                          onClick={() => handlerFinishPaymentMethod()}
                                          disabled={editTotalTransaction}
                                        >
                                          Pagar
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                )}
                            </div>
                          )
                        }
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalAlertNoEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseNoEditModal(); } }}
        transitionDuration={{ enter: 100, exit: 0 }}
      >
        <DialogTitle>
          Mensaje informativo
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-3">
              El producto no se puede adicionar porque esta
              venta ya cuenta con Metodo y Forma de pago asignado.
            </div>
            <div className="col-12 mb-2">
              <button type="button" className="btn btn-primary w-100" onClick={handlerCloseNoEditModal}>
                Entendido
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default NuevaOrden;
