// EC2 URL
// Dev
// export const urlAws = 'https://localhost:4000/';
// Prod
// export const urlAws = 'https://d3090mxcwnzsww.cloudfront.net/';
// test
export const urlAws = 'https://d3om7dqubtm6z6.cloudfront.net/';
// export const urlAws = 'https://ec2-52-91-7-242.compute-1.amazonaws.com:4000/';
// pdf
// export const urlpdf = 'http://192.168.20.22:3000/#/';

export const urlpdf = 'https://tf-payments.io/#/';

// URL UP KEY
export const upKey = 'UvMWsWQP71UP-gtam3cO1vDscvPQdXgjmxl5KaQoEiw';

// upCognito
export const upCognito = 'us-east-1_AZGQj1xpA';

export const isNumeric = (value) => {
  // La expresión regular /^[0-9]+$/ asegura que el valor consista en uno o más dígitos numéricos
  return /^[0-9]+$/.test(value);
}