import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { grey, blue } from '@mui/material/colors';
import BillToPrint from './billToPrint';
import { useReactToPrint } from 'react-to-print';

const ColorButton = styled(Button)(() => ({
  color: grey[50],
  backgroundColor: blue[500],
  '&:hover': {
    backgroundColor: blue[800],
  },
}));

function Pdf() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const componentToPrint = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentToPrint.current,
  });

  return (
    <div className="w-100 h-100-i justify-content-center bg-white">
      <BillToPrint ref={componentToPrint} f={query.get('f')} c={query.get('c')} d={query.get('d')} />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <ColorButton
          variant="contained"
          className="w-50"
          type="button"
          onClick={handlePrint}
        >
          Imprimir
        </ColorButton>
      </div>
    </div>
  );
}

export default Pdf;
