/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import MaterialTable from '@material-table/core';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import {
  setEmployees, insertEmployees, updateEmployees, deleteEmployees,
} from '../reducers/employees';
import { setRol } from '../reducers/rol';

import {
  getRequest, postRequest, putRequest, deleteRequest, getSubMenu, getLangUser,
} from '../Cache/cacheStorage';
import { labelLang } from '../Lang/lang';

function Employee() {
  const submenu = getSubMenu('settings', 'employee');

  const apiUrl = 'employees';
  const storedData = 'employees';
  const [item, setItem] = useState({
    id: '',
    name: '',
    lastName: '',
    role: '',
    roleName: '',
    address: '',
    phoneNumber: '',
    email: '',
    active: true,
    sendEmail: false,
    enableEmail: false
  });
  const [modalItem, setmodalItem] = useState(false);
  const [deleteItem, setdeleteItem] = useState('');
  const [modalDelete, setmodalDelete] = useState(false);

  const [msmAlert, setmsmAlert] = useState('');
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);

  const data = useSelector((state) => state.employees.value);

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  /* A function that is used to set the rol. */
  const handlerSetEmployees = (infoEmployee) => {
    dispatch(setEmployees(infoEmployee));
  };

  const handlerInsertEmployee = (infoEmployee) => {
    dispatch(insertEmployees(infoEmployee));
  };

  const handlerUpdateEmployee = (infoEmployee) => {
    dispatch(updateEmployees(infoEmployee));
  };

  const handlerDeleteEmployee = (infoEmployee) => {
    dispatch(deleteEmployees(infoEmployee));
  };

  const getEmployee = async () => {
    const requestEmployee = await getRequest(apiUrl, {}, storedData, false);
    handlerSetEmployees(requestEmployee.data.dataResponse);
  };

  useEffect(() => {
    if (data.length === 0) {
      getEmployee();
    }
  }, []);

  const role = useSelector((state) => state.rol.value);

  const handlerSetRol = (infoRol) => {
    dispatch(setRol(infoRol));
  };

  const getRol = async () => {
    const requestRol = await getRequest('role', {}, 'role', false);
    handlerSetRol(requestRol.data.dataResponse);
  };

  useEffect(() => {
    if (role.length === 0) {
      getRol();
    }
  }, []);

  const handlerOpenModalItem = (id) => {
    if (id !== '') {
      const obj = data.filter((x) => x.id === id);
      setItem(obj[0]);
    }

    setmodalItem(true);
  };

  const handlerCloseModalItem = () => {
    setItem({
      id: '',
      name: '',
      lastName: '',
      role: '',
      roleName: '',
      address: '',
      phoneNumber: '',
      email: '',
      active: true,
      sendEmail: false,
      enableEmail: false
    });

    setmodalItem(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeStock = (e) => {
    const { name, checked } = e.target;
    setItem((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handlerItem = async (dataItem, method) => {
    if (method === 'POST') {
      const createData = await postRequest(apiUrl, dataItem, storedData, false);

      if (createData.status === 200) {
        handlerInsertEmployee(dataItem);
        setItem({
          id: '',
          name: '',
          lastName: '',
          role: '',
          roleName: '',
          address: '',
          phoneNumber: '',
          email: '',
          active: true,
          sendEmail: false,
          enableEmail: false
        });
        setmodalItem(false);
        setmsmAlert('El Empleado se ha creado exitosamente.');
        setalertSuccess(true);
      } else {
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }
    }

    if (method === 'PUT') {
      const updateData = await putRequest(apiUrl, dataItem, storedData, false);

      if (updateData.status === 200) {
        getEmployee();
        handlerUpdateEmployee(dataItem);
        setItem({
          id: '',
          name: '',
          lastName: '',
          role: '',
          roleName: '',
          address: '',
          phoneNumber: '',
          email: '',
          active: true,
          sendEmail: false,
          enableEmail: false
        });
        setmodalItem(false);
        setmsmAlert('El Empleado se ha actualizado exitosamente.');
        setalertSuccess(true);
      } else {
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }
    }
  };

  const handlerEmailEmployee = async (dataItem, verified) => {
    if (verified) {
      const emailVerified = await postRequest('enable-email-user', dataItem, 'enable-email-user', false);
      if (emailVerified.status === 200) {
        setmsmAlert('El Email se ha registrado exitosamente.');
        setalertSuccess(true);
      }
    } else {
      const emailDelete = await postRequest('delete-email-user', dataItem, 'delete-email-user', false);
      if (emailDelete.status === 200) {
        setmsmAlert('El Email se ha eliminado exitosamente.');
        setalertSuccess(true);
      }
    }
  };

  const handlerProcess = () => {
    const dataItem = item;
    const method = dataItem.id === '' ? 'POST' : 'PUT';
  
    if (dataItem.id === '') {
      dataItem.id = uuidv4();
    }
  
    const roleItem = role.find((x) => x.id === dataItem.role);
    if (roleItem) {
      dataItem.roleName = roleItem.name;
    }
  
    const existingDataItem = data.find((x) => x.id === dataItem.id);
    if (!existingDataItem) {
      handlerEmailEmployee(dataItem, dataItem.enableEmail);
    } else {
      if (dataItem.enableEmail !== existingDataItem.enableEmail) {
        handlerEmailEmployee(dataItem, dataItem.enableEmail);
      }
    }
  
    handlerItem(dataItem, method);
  };

  const handlerOpenModalDelete = (id) => {
    const obj = data.find((x) => x.id === id);
    setdeleteItem(obj);

    setmodalDelete((modal) => !modal);
  };

  const handlerCloseModalDelete = () => {
    setmodalDelete((modal) => !modal);
  };

  const handlerDelete = async () => {
    const deleteData = await deleteRequest('employees', { id: deleteItem.id }, storedData, false);
    if (deleteData.status === 200) {
      if (deleteData.data.status === false) {
        setdeleteItem('');
        handlerCloseModalDelete();
        setmsmAlert('Error en el registro, por favor intente de nuevo.');
        setalertError(true);
      }

      if (deleteData.data.status === true) {
        handlerEmailEmployee(deleteItem, false);

        setmsmAlert('Se ha eliminado con exito.');
        setalertSuccess(true);
        handlerDeleteEmployee(deleteItem);
        setdeleteItem('');
        setmodalDelete(false);
      }
    } else {
      setmsmAlert('Error en el registro, por favor intente de nuevo.');
      setalertError(true);
    }
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertSuccess(false);
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setalertError(false);
  };

  return (
    <div className="h-100 px-3 bg-white btn-rounded inside-scroll-container">

      <MaterialTable
        title={<h3>Empleados</h3>}
        columns={[
          {
            title: 'Nombre',
            field: 'name',
          },
          {
            title: 'Apellido',
            field: 'lastName',
          },
          {
            title: 'Dirección',
            field: 'address',
          },
          {
            title: '# Teléfono',
            field: 'phoneNumber',
          },
          {
            title: 'Email',
            field: 'email',
          },
          {
            title: 'Cargo',
            field: 'roleName',
          },
        ]}
        data={data}
        actions={
          [
            (rowdata) => ({
              icon: 'edit',
              tooltip: 'Actualizar',
              onClick: (event, rowData) => handlerOpenModalItem(rowData.id),
              disabled: submenu.update !== true,
              hidden: rowdata.isAdmin === true,
            }),
            (rowdata) => ({
              icon: 'delete',
              tooltip: 'Eliminar',
              onClick: (event, rowData) => handlerOpenModalDelete(rowData.id),
              disabled: submenu.delete !== true,
              hidden: rowdata.isAdmin === true,
            }),
            {
              icon: 'add',
              tooltip: 'Crear',
              isFreeAction: true,
              onClick: () => handlerOpenModalItem(''),
              disabled: submenu.create !== true,
            },
          ]
        }
        options={{
          maxColumnSort: 'all_columns',
          paging: data.length > 100,
          pageSize: 100,
          pageSizeOptions: [100, 150, 200, 300],
          rowStyle: () => {
            return { height: '59px' };
          },
        }}
        localization={{
          toolbar: {
            searchPlaceholder: labelLang(getLangUser(), 'table', 'searchPlaceholder'),
            searchTooltip: labelLang(getLangUser(), 'table', 'searchTooltip'),
          },
          header: {
            actions: labelLang(getLangUser(), 'table', 'actions'),
          },
          pagination: {
            labelRowsPerPage: labelLang(getLangUser(), 'table', 'labelRowsPerPage'),
            labelDisplayedRows: labelLang(getLangUser(), 'table', 'labelDisplayedRows'),
            labelRowsSelect: labelLang(getLangUser(), 'table', 'labelRowsSelect'),
            firstAriaLabel: labelLang(getLangUser(), 'table', 'firstAriaLabel'),
            firstTooltip: labelLang(getLangUser(), 'table', 'firstTooltip'),
            previousAriaLabel: labelLang(getLangUser(), 'table', 'previousAriaLabel'),
            previousTooltip: labelLang(getLangUser(), 'table', 'previousTooltip'),
            nextAriaLabel: labelLang(getLangUser(), 'table', 'nextAriaLabel'),
            nextTooltip: labelLang(getLangUser(), 'table', 'nextTooltip'),
            lastAriaLabel: labelLang(getLangUser(), 'table', 'lastAriaLabel'),
            lastTooltip: labelLang(getLangUser(), 'table', 'lastTooltip'),
          },
        }}
      />

      <Dialog
        open={modalItem}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalItem(); } }}
      >
        <DialogTitle>
          {item.id ? (
            <span>Editar</span>
          ) : (
            <span>Crear</span>
          )}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3">
              <TextField
                variant="standard"
                error={item.name === ''}
                label="Nombre(s) *"
                className="w-100"
                name="name"
                value={item.name}
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3">
              <TextField
                variant="standard"
                error={item.lastName === ''}
                label="Apellido(s) *"
                className="w-100"
                name="lastName"
                value={item.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="label-select-category">Rol</InputLabel>
                <Select
                  id="select-rol"
                  labelId="select-rol"
                  name="role"
                  value={item.role}
                  onChange={handleChange}
                >
                  {
                    role.map((rol) => (
                      <MenuItem key={rol.id} value={rol.id}>{rol.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3">
              <TextField
                variant="standard"
                error={item.email === ''}
                label="Email *"
                className="w-100"
                name="email"
                value={item.email}
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3">
              <TextField
                variant="standard"
                error={item.address === ''}
                label="Dirección *"
                className="w-100"
                name="address"
                value={item.address}
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3">
              <TextField
                variant="standard"
                error={item.phoneNumber === ''}
                label="N° Telefono *"
                className="w-100"
                name="phoneNumber"
                value={item.phoneNumber}
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-2">
              <FormControlLabel
                control={(
                  <Checkbox
                    name="enableEmail"
                    checked={item.enableEmail}
                    onChange={handleChangeStock}
                  />
                )}
                label="Inscribir al servicio de Email"
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-2">
              <FormControlLabel
                control={(
                  <Checkbox
                    name="sendEmail"
                    disabled={item.enableEmail === false}
                    checked={item.sendEmail}
                    onChange={handleChangeStock}
                  />
                )}
                label="¿Enviar Emails?"
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
              <FormControlLabel
                control={(
                  <Checkbox
                    name="active"
                    checked={item.active}
                    onChange={handleChangeStock}
                  />
                )}
                label="Activo"
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalItem}>Cancelar</button>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={handlerProcess}
                disabled={
                  item.name === '' || item.lastName === '' || item.role === '' || item.email === '' || item.address === '' || item.phoneNumber === ''
                }
              >
                Guardar
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        transitionDuration={{ enter: 100, exit: 0 }}
        onClose={(event, reason) => { if (reason !== 'backdropClick') { handlerCloseModalDelete(); } }}
      >
        <DialogTitle>
          Eliminar
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mb-3">
              ¿Seguro desea eliminar el Empleado?
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-danger w-100" type="button" onClick={handlerCloseModalDelete}>Cancelar</button>
            </div>
            <div className="col-6 mb-2">
              <button className="btn btn-primary w-100" type="button" onClick={handlerDelete}>Eliminar</button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertSuccess}
        autoHideDuration={3000}
        onClose={handleCloseAlertSuccess}
      >
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertError}
        autoHideDuration={3000}
        onClose={handleCloseAlertError}
      >
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%' }}>
          {msmAlert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Employee;
